.bloggers_search_wrapper{
  .block_panel_blogs{
    padding:  50px 0 30px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .options_wrapper {
      display: flex;
      .filters_wrapper {
        display: none;
      }
      .filters_opened_wrapper {
        display: flex;
        position: absolute;
        flex-flow: column;
        background-color: #FFFFFF;
        box-shadow: 0 8px 32px -8px rgba(0,34,24,0.15);
        padding: 30px;
        width: calc(100% - 60px);
        top: 100px;
        z-index: 1000;
        .filters_block {
          padding: 35px 0;
          border-bottom: 1px solid rgba(151, 151, 151, 0.2);
          display: flex;
          &:first-of-type {
            padding-top: 0;
          }
          .filter_block {
            margin-right: 100px;
            .filters_bigger_title {
              color: #1A1A1A;
              font-family: "GothaProBold",sans-serif;
              font-size: 14px;
              margin-bottom: 21px;
            }
            .filters_smaller_title {
              color: #1A1A1A;
              font-family: "GothaProBold",sans-serif;
              font-size: 12px;
              margin-bottom: 16px;
            }
            .filter_labels {
              color: #1A1A1A;
              font-family: "GothaProReg",sans-serif;
              font-size: 12px;
              margin-bottom: 12px;
              label {
                user-select: none;
                cursor: pointer;
              }
            }
            .filter_choices {
              color: #1A1A1A;
              font-family: "GothaProReg",sans-serif;
              font-size: 12px;
              display: flex;
              flex-flow: column;
              label {
                user-select: none;
                cursor: pointer;
                display: flex;
                margin-bottom: 13px;
                input {
                  margin-right: 6px;
                  cursor: pointer;
                  background-color: #FFFFFF;
                  border: 1px solid #5CE6CD;
                  border-radius: 10px;
                  cursor: pointer;
                  display: inline-block;
                  height: 12px;
                  position: relative;
                  width: 12px;
                  -webkit-appearance: none;
                  &:checked:after {
                    display: block;
                    position: relative;
                    border-radius: 50%;
                    content: '';
                    background-color: #5CE6CD;;
                    height: 6px;
                    width: 6px;
                    top: 2px;
                    left: 2px;
                  }
                }
              }
            }
            .multi_div {
              display: flex;
              flex-flow: column;
              justify-content: space-between;
              align-items: center;
              >div{
                display: flex;
                flex-flow: column;
                margin-bottom: 20px;
                .descriptions{
                  font-family: "GothaProMed",sans-serif;
                  font-size: 12px;
                  padding-bottom: 12px;
                }
                p{
                  font-family: "GothaProLig",sans-serif;
                  font-size: 14px;
                }
                >div {
                  width: 310px;
                }
              }
            }
            .select_wrapper{
              height: 32px;
              width: 310px;
              padding-bottom: 20px;
              .select_component .select__control{
                border: 1px solid #EDEDED;
                background: #ffffff;
                .select__value-container{
                  padding: 2px 8px 2px 12px;
                  font-size: 10px;
                  font-family: "GothaProReg",sans-serif;
                }
              }
              .select__indicator {
                padding: 8px 19px;
              }
            }
            .filters-input{
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .arrow{
                box-sizing: border-box;
                height: 1px;
                width: 10px;
                background: #9B9B9B;
                opacity: 0.5;
                margin: 0 5px;
              }
              input{
                font-family: "GothaProReg",sans-serif;
                box-sizing: border-box;
                height: 32px;
                width: 140px;
                border: 1px solid #EDEDED;
                font-size: 10px;
                padding: 0 12px;
              }
            }
            .rc-slider-tooltip {
              .rc-slider-tooltip-content {
                .rc-slider-tooltip-inner {
                  padding: 6px 2px;
                  min-width: 24px;
                  height: 24px;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 1;
                  color: #fff;
                  text-align: center;
                  text-decoration: none;
                  background-color: transparent;
                  box-shadow: none;
                }
              }
            }

            .rc-slider {
              width: 310px;
              .rc-slider-track {
                height: 3px;
                background: #5CE6CD;
              }
              .rc-slider-rail {
                height: 3px;
                background: rgba(51, 228, 179, 0.3);
              }
              .rc-slider-handle {
                height: 12px;
                width: 12px;
                border: 3px solid #5CE6CD;
                background-color: #fff;
                &:focus {
                  box-shadow: none;
                }
              }
            }
            .filters_gender {
              display: flex;
              margin-bottom: 20px;
              button{
                width: 32px;
                height: 32px;
                border: 1.5px solid #EDEDED;
                cursor: pointer;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                img {
                  width: 15px;
                  height: 15px;
                }
              }
              .active{
                border: 1.5px solid #5CE6CD;
              }
            }
          }
        }
        .buttons_wrapper {
          display: flex;
          justify-content: flex-end;
          padding-top: 30px;
          & > button {
            width: 190px;
            height: 50px;
            margin-left: 20px;
          }
        }
      }
    }
    .search_blogs{
      position: relative;
      input{
        height: 40px;
        width: 310px;
        border: 1px solid #EDEDED;
        padding: 0 40px 0 15px;
        font-size: 12px;
        display: flex;
        align-items: center;
        font-family: "GothaProReg",sans-serif;
        &::placeholder{
          color: #9B9B9B;
        }
      }
      button{
        width: 11px;
        height: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 15px;
        top: calc(50% - 5.5px);
        cursor: pointer;
        background: transparent;
        opacity: 0.2;
      }
    }
    .filters_btn {
      height: 42px;
      width: 150px;
      background-color: #EDEDED;
      margin: 0 32px 0 50px;
      color: #1A1A1A;
      font-family: "GothaProBold",sans-serif;
      font-size: 10px;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
      & > span {
        display: flex;
        img {
          margin: 0 21px 0 15px;
        }
      }
    }
    .filters_opened {
      position: relative;
      &:before{
        position: absolute;
        content: "";
        left: calc(50% - 4px);
        background: #EDEDED;
        transform: rotate(45deg);
        bottom: -5px;
        width: 8px;
        height: 8px;
        border-left: 1px solid #EDEDED;
        border-top: 1px solid #EDEDED;
      }
    }
    .reset_filters {
      color: #9B9B9B;
      font-family: "GothaProMed",sans-serif;
      font-size: 10px;
      background-color: transparent;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    .sort_by {
      color: #1A1A1A;
      font-family: "GothaProReg",sans-serif;
      font-size: 12px;
      line-height: 40px;
      margin-right: 24px;
    }
  }

  .block_info{
    display: flex;
    align-items: center;
    justify-content: center;
    .block_tooltip{
      position: relative;
      margin-left: 5px;
      cursor: pointer;
      &:hover{
        .tooltip{
          display: flex;
        }
        .tooltip_big{
          display: flex;
        }
      }
      .tooltip{
        display: none;
        width: 240px;
        border: 1px solid #EDEDED;
        color: #1A1A1A;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        background: #fff;
        position: absolute;
        text-align: center;
        box-sizing: border-box;
        left: calc(50% - 120px);
        top: 25px;
        z-index: 10;
        text-transform: none;
        &:before{
          position: absolute;
          content: "";
          left: calc(50% - 4px);
          background: #fff;
          transform: rotate(45deg);
          top: -5px;
          width: 8px;
          height: 8px;
          border-left: 1px solid #EDEDED;
          border-top: 1px solid #EDEDED;
        }
      }
      .tooltip_big{
        text-transform: none;
        z-index: 10;
        position: absolute;
        display: none;
        width: 320px;
        border: 1px solid #EDEDED;
        color: #1A1A1A;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        background: #fff;
        text-align: center;
        box-sizing: border-box;
        left: calc(50% - 160px);
        top: 25px;
        &:before{
          position: absolute;
          content: "";
          left: calc(50% - 4px);
          background: #fff;
          transform: rotate(45deg);
          top: -5px;
          width: 8px;
          height: 8px;
          border-left: 1px solid #EDEDED;
          border-top: 1px solid #EDEDED;
        }
      }
    }
  }
  .center{
    text-align: center;
    width: 100%;
  }

  .info-price{
    cursor: pointer;
    position: relative;
    display: inline-block;
    &:hover{
      .tooltip{
        display: flex;
      }
    }
    .price{
      color: #4A90E2;
      font-size: 12px;
      font-family: "GothaProMed",sans-serif;
    }

    .tooltip{
      flex-flow: column;
      position: absolute;
      display: none;
      width: 340px;
      border: 1px solid #EDEDED;
      color: #1A1A1A;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      padding: 20px;
      background: #fff;
      text-align: center;
      box-sizing: border-box;
      left: calc(50% - 170px);
      top: 25px;
      z-index: 15;
      &:before{
        position: absolute;
        content: "";
        left: calc(50% - 4px);
        background: #fff;
        transform: rotate(45deg);
        top: -5px;
        width: 8px;
        height: 8px;
        border-left: 1px solid #EDEDED;
        border-top: 1px solid #EDEDED;
      }
      >div{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 18px;

      }
      .title{
        font-size: 12px;
        padding-bottom: 12px;
      }
      .light{
        font-family: "GothaProLig",sans-serif;
      }
      span{
        font-family: "GothaProMed",sans-serif;
      }

    }
  }
  .error {
    font-size: 12px;
    color: red;
  }
}

.bloggers_search_table{
  box-shadow: 0 8px 32px -8px rgba(0,34,24,0.15);
  .transactions_columns {
    width: 100%;
    .table_header {
      .row_item {
        display: flex;
        align-items: center;
      }
    }
    .table_body{
      a{
        text-decoration: none;
      }
    }
    .table_row {
      & > :first-child {
        width: 30%;
      }
      & > :nth-child(2) {
        width: 10%;
      }
      & > :nth-child(3) {
        width: 10%;
      }
      & > :nth-child(4) {
        width: 10%;
      }
      & > :nth-child(5) {
        width: 10%;
        justify-content: center;
      }
      & > :nth-child(6) {
        width: 10%;
        img{
          margin-right: 10px;
        }
      }
      & > :nth-child(7) {
        width: 10%;
        display: flex;
        justify-content: center;
      }
      & > :last-child {
        width: 10%;
      }
    }
  }
}
.countries{
  display: flex;
  align-items: center;
  justify-content: center;
  .flag-icon {
    border-radius: 50%;
    border: 1px solid #EDEDED;
  }
}

.info_blogers_wrapper{
  width: 100%;
  display: flex;
  color: #334150;
  .box_photo{
    padding-right: 20px;
    padding-left: 30px;
    img{
      width: 60px;
      height: 60px;
      background-size: 100% 100%;
    }
  }
  .info_blogers{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    justify-content: center;
    display: flex;
    flex-flow: column;
    width: calc(100% - 20px);
    .name{
      font-size: 12px;
      font-family: "GothaProMed",sans-serif;
      padding-bottom: 8px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      max-width: calc(100% - 20px);
      p {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      img{
        padding-left: 5px;
        width: 14px;
        height: 10px;
      }
    }
  }
  .info{
    display: flex;
    flex-flow: column;
    max-width: 250px;
  }
  .wrapper_info{
    display: flex;
    div{
      font-size: 10px;
    }
    span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 10px;
      padding-left: 3px;
      opacity: 0.8;
      font-family: "GothaProLig",sans-serif;
    }
  }
}

.get_price {
  background-color: unset;
  color: #4A90E2;
  font-size: 12px;
  font-family: "GothaProMed",sans-serif;
  transition: all 0.2s linear;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &.not_active {
    cursor: help;
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }
}

.get_price_with_tooltip {
  position: relative;
  &:hover{
    .tooltip{
      display: flex;
    }
  }
  .tooltip{
    flex-flow: column;
    position: absolute;
    display: none;
    border: 1px solid #EDEDED;
    color: #1A1A1A;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    background: #fff;
    text-align: center;
    box-sizing: border-box;
    left: calc(50% - 130px);
    top: -48px;
    z-index: 15;
    &:before{
      position: absolute;
      content: "";
      left: calc(50% - 4px);
      background: #fff;
      transform: rotate(-135deg);
      bottom: -5px;
      width: 8px;
      height: 8px;
      border-left: 1px solid #EDEDED;
      border-top: 1px solid #EDEDED;
    }
  }
}

