.public_offer {
  p, span {
    font-family: "GothaProReg",sans-serif!important;
    font-size: 14px!important;
    line-height: 22px!important;
  }
  h1 {
    margin-bottom: 16px!important;
    span{
      font-size: 32px!important;
      font-family: "GothaProBold", sans-serif!important;
    }
  }
  &.privacy_policy {
    h3 {
      margin-bottom: 16px!important;
      span{
        font-size: 32px!important;
        font-family: "GothaProBold", sans-serif!important;
      }
    }
  }
}
