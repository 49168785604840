.public_container {
  .public_header {
    height: 128px;
    background-color: #5CE6CD;
    background-image: url("../../assets/image/public_header.png");
    background-repeat: no-repeat;
  }
  .public_go_back {
    padding: 40px 150px 0;
    color: #9B9B9B;
    font-size: 12px;
    user-select: none;
    & > div {
      width: max-content;
      cursor: pointer;
      transition: all 0.2s linear;
      &:hover{
        opacity: 0.7;
      }
    }
    img {
      margin-right: 6px;
    }
  }
  .public_wrapper {
    padding: 0 50px;
  }
}
