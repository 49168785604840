.offer_form {
    .offer_link {
        width: 100%;
        height: 56px;
        font-size: 14px;
        line-height: 24px;
        color: #4590e8;
        background-color: rgba(69, 144, 232, 0.1);
        font-weight: bold;
        display: flex;
        align-items: center;
        padding-left: 20px;
        margin-top: 20px;
        text-decoration: none;
    }
    .checkbox {
        margin-top: 32px;
        .offer_label {
            display: flex;
            color: #1a1a1a;
            font-size: 12px;
            line-height: 16px;
        }
    }
    hr {
        height: 1px;
        width: 100%;
        border: 1px solid #979797;
        opacity: 0.2;
        box-sizing: border-box;
        margin: 40px 0;
    }

    .btn_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        .button {
            height: 50px;
            width: 190px;
            background-color: #5ce6cd;
            font-size: 14px;
            font-weight: bold;
            line-height: 13px;
            color: #1a1a1a;
            cursor: pointer;
            &.btn-disabled {
                cursor: none;
                opacity: 0.6;
            }
        }
    }
}
