.finance_page_wrapper{
  h1{
    margin-bottom: 50px;
  }
  .finance_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;

    > div {
      display: flex;
      flex-flow: column;
      > div {
      font-size: 14px;
      line-height: 16px;
      display: flex;
      padding-bottom: 5px;

      > p {
        padding-left: 3px;
        font-family: "GothaProBold", sans-serif;
      }
      }
    }

    >button {
      padding: 12px 21.5px;
      background-color: #5CE6CD;
      color: #1A1A1A;
      font-family: "GothaProBold", sans-serif;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 10px;
      text-transform: uppercase;
      cursor: pointer;
    }

    & > p {
      color: #1A1A1A;
      font-size: 12px;
      line-height: 14px;

      .reserved {
        margin-right: 3px;
        font-family: "GothaProLig", sans-serif;
      }
    }
  }
  .transactions_columns {
    width: 100%;
    .table_header {
      .row_item {
        display: flex;
        align-items: center;
      }
    }
    .table_body{
      .table_row{
        height: 80px;
        .row_item{
          color: #1A1A1A;
        }
      }
    }
    .table_row {
      line-height: 16px;
      & > :first-child {
        width: 12%;
        padding-left: 30px;
        >div{
          display: flex;
          flex-flow: column;
        }
      }
      & > :nth-child(2) {
        width: 16%;
        font-family: "GothaProBold",sans-serif;

      }
      & > :nth-child(3) {
        width: 15%;
      }
      & > :nth-child(4) {
        width: 15%;
      }
      & > :nth-child(5) {
        span{
          max-width: 135px;
        }
        width: 15%;
      }
      & > :nth-child(6) {
        width: 15%;
        .text_red{
          color: #D0021B;

        }
        .text_green{
          color: #2CC197;
        }
      }
      & > :last-child {
        width: 13%;
      }
    }
  }
  .pagination_block{
    .pagination-container{
      padding: 30px;
      border-top: 1px solid rgba(151, 151, 151, 0.2);
    }
  }

}

.no_finance{
  padding: 80px 0;
  display: flex;
  flex-flow: column;
  text-align: center;
  .descriptions{
    color: #9B9B9B;
    font-size: 24px;
    line-height: 24px;
    padding-top: 32px;

  }
}

.payment_details_dialog .finance_page_form .row .input_field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  input {
    box-sizing: border-box;
    height: 50px;
    width: 165px;
    border: 1px solid #EDEDED;
    padding: 0 60px 0 20px;
    //color: #9B9B9B;
    font-family: "GothaProReg", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 13px;
    margin-left: 16px;
  }
  p {
      position: absolute;
      top: 15px;
      right: 20px;
      color: #9B9B9B;
  }
}