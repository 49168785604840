input[type=checkbox] {
  display: none;
}

input[type=checkbox] + span {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  border: 1px solid #979797;
  background-color: #FFFFFF;
  color: #2CC197;
  height: 12px;
  width: 12px;
  margin: 0px 7px 0 0;
}

input[type=checkbox]:hover + span{
  box-shadow: none!important;
}

input[type=checkbox]:checked + span::after {
  content: "";
  display: block;
  width: 3px;
  height: 5px;
  border: solid #2CC197;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 4px;
}

input[type=checkbox]:checked + span {
 border-color: #2CC197;
}

input[type=checkbox]:hover + span {
  box-shadow:0px 0px 5px #2CC197;
}