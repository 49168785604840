body {
    font-family: "GothaProReg", sans-serif;
    color: #1a1a1a;
    max-height: 100vh;
    overflow: auto;
    box-sizing: border-box;
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;

        background: #edf2f8;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: #5ce6cd;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: #5ce6cd;
    }
}

.content_block {
    width: 100%;
    display: flex;
    padding: 50px 100px;
    flex-flow: column;
    box-sizing: border-box;
}
.content_block_page {
    width: 100%;
    display: flex;
    padding: 50px;
    flex-flow: column;
    box-sizing: border-box;
}

a {
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

button {
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

h1 {
    color: #1a1a1a;
    font-family: "GothaProBold", sans-serif;
    font-size: 32px;
    line-height: 30px;
}

.block_page {
    display: flex;
}

.ru_value {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    padding-left: 3px;
}
.bg_orange_hide {
    background-color: #f0c57d;
}
.bg_purpl {
    background: #bd10e0;
}
.bg_green {
    background: #417505;
}
.bg_blue {
    background: #4a90e2;
}
.bg_orange {
    background: #f5a623;
}
.bg_green_light {
    background: #5ce6cd;
}

.btn_border {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #1a1a1a;
    font-family: "GothaProBold", sans-serif;
    line-height: 13px;
    font-size: 10px;
    height: 40px;
    width: 150px;
    cursor: pointer;
    border: 1px solid #5ce6cd;
    background: transparent;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: relative;
    &:before {
        position: absolute;
        content: "";
        width: 0%;
        height: 100%;
        background: rgba(92, 230, 205, 0.2);
        top: 0;
        left: 0;
        z-index: -1;
        transition: all 0.1s linear;
    }
    &:hover {
        &:before {
            width: 100%;
        }
    }
    &:disabled {
        opacity: 0.6;
        //box-shadow: 0 8px 32px -8px rgba(0, 34, 24, 0.35);
    }
}

.green_btn_big {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #1a1a1a;
    font-family: "GothaProBold", sans-serif;
    font-size: 14px;
    line-height: 13px;
    height: 50px;
    width: 100%;
    cursor: pointer;
    background-color: #5ce6cd;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: relative;
    border: 1px solid transparent;
    &:before {
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        position: absolute;
        content: "";
        right: 70px;
        top: calc(50% - 13px);
        background: url("../assets/image/direction.png");
        width: 28px;
        height: 26px;
        transform: rotate(180deg);
    }
    &:hover {
        background: #fff;
        border: 1px solid #5ce6cd;
        &:before {
            right: 50px;
            opacity: 1;
        }
    }
    &:disabled {
        opacity: 0.6;
        box-shadow: 0 8px 32px -8px rgba(0, 34, 24, 0.35);
    }
}
.green_btn {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #1a1a1a;
    font-family: "GothaProBold", sans-serif;
    font-size: 14px;
    line-height: 13px;
    height: 50px;
    width: 100%;
    cursor: pointer;
    background-color: #5ce6cd;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: relative;
    border: 1px solid transparent;
    &:before {
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        position: absolute;
        content: "";
        right: 28px;
        top: calc(50% - 7px);
        background: url("../assets/image/direction.png");
        background-size: 100% 100%;
        width: 14px;
        height: 14px;
        transform: rotate(180deg);
    }
    &:hover {
        background: #fff;
        border: 1px solid #5ce6cd;
        &:before {
            right: 10px;
            opacity: 1;
        }
    }
    &:disabled {
        opacity: 0.6;
        box-shadow: 0 8px 32px -8px rgba(0, 34, 24, 0.35);
        &:hover {
            background-color: #5ce6cd;
            cursor: not-allowed;
            &:before {
                right: 28px;
                opacity: 0;
            }
        }
    }
}

.cancel_btn {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #9b9b9b;
    border: 1px solid #979797;
    font-family: "GothaProBold", sans-serif;
    font-size: 14px;
    line-height: 13px;
    height: 50px;
    width: 100%;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    background: transparent;
    z-index: 1;
    &:before {
        position: absolute;
        content: "";
        width: 0%;
        height: 100%;
        background: rgba(155, 155, 155, 0.1);
        top: 0;
        left: 0;
        z-index: -1;
        transition: all 0.1s linear;
    }
    &:hover {
        &:before {
            width: 100%;
        }
    }
    &:disabled {
        opacity: 0.5;
        cursor: wait;
        &:hover {
            &:before {
                width: 0;
            }
        }
    }
}

.red_btn {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #d0021b;
    border: 1px solid #d0021b;
    font-family: "GothaProBold", sans-serif;
    font-size: 14px;
    line-height: 13px;
    height: 50px;
    width: 100%;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    background: transparent;
    z-index: 1;
    &:before {
        position: absolute;
        content: "";
        width: 0%;
        height: 100%;
        background: rgba(208, 2, 27, 0.1);
        top: 0;
        left: 0;
        z-index: -1;
        transition: all 0.1s linear;
    }
    &:hover {
        &:before {
            width: 100%;
        }
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        &:hover {
            &:before {
                width: 0;
            }
        }
    }
}

.border_btn_green {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background: #5ce6cd;
    color: #1a1a1a;
    border: 1px solid #5ce6cd;
    font-family: "GothaProBold", sans-serif;
    font-size: 14px;
    line-height: 13px;
    height: 50px;
    width: 100%;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    z-index: 1;
    &:before {
        position: absolute;
        content: "";
        width: 0%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        top: 0;
        left: 0;
        z-index: -1;
        transition: all 0.1s linear;
    }
    &:hover {
        &:before {
            width: 100%;
        }
    }
    &:disabled {
        opacity: 0.6;
        &:hover {
            cursor: not-allowed;
            &:before {
                width: 0%;
            }
        }
    }
}

.table_container {
    .table_header .table_header {
        display: flex;
    }
}

.table_header {
    .row_item {
        color: rgba(26, 26, 26, 0.5);
        font-size: 8px;
        text-transform: uppercase;
    }
    .table_row {
        width: 100%;
        display: flex;
        align-items: center;
        height: 50px;
    }
}

.table_body {
    .row_item {
        color: #334150;
        font-size: 12px;
    }
    .table_row {
        width: 100%;
        display: flex;
        align-items: center;
        height: 98px;
        border-top: 1px solid rgba(151, 151, 151, 0.2);
        text-decoration: none;
    }
}

.back_error {
    color: #d0021b;
    font-size: 10px;
    line-height: 13px;
    width: 100%;
    text-align: end;
    font-family: "GothaProMed", sans-serif;
}

.error_sign_up {
    margin: -25px 0 12px;
}

.text_back_error_pos_abs {
    position: absolute;
    bottom: -15px;
}

.pos_rel {
    position: relative;
}

.text_red {
    color: #d0021b;
}
.text_green {
    color: #2cc197;
}

.inp-checkbox {
    display: none;
    & + label:before {
        background: url("../assets/image/unselected.png") no-repeat;
        content: "";
        height: 12px;
        width: 14px;
        display: inline-block;
        padding: 0;
        margin-right: 8px;
        margin-top: 2px;
        background-position-y: bottom;
    }
    &:checked {
        & + label:before {
            background: url("../assets/image/selected.png") no-repeat;
            height: 12px;
            width: 14px;
            display: inline-block;
            padding: 0;
            background-position-y: bottom;
        }
    }
}

.loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(92, 230, 205, 0.02);
}

textarea {
    max-height: 96px;
    overflow: auto;
    box-sizing: border-box;
    font-family: "GothaProReg", sans-serif;
    &::placeholder {
        font-family: "GothaProLig", sans-serif;
    }
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        border-radius: 2px;
        margin-right: 5px;
        background: #edf2f8;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #5ce6cd;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: #5ce6cd;
    }
}
input {
    &:disabled {
        cursor: not-allowed;
    }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.lazy_loader_wrapper {
    width: 100%;
    text-align: center;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rounded_image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.text_ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.arbitrage_icon {
    margin-left: 12px;
    position: relative;
    cursor: help;
    &:hover {
        .tooltip {
            display: flex;
        }
    }
    .tooltip {
        font-family: "GothaProLig", sans-serif;
        flex-flow: column;
        position: absolute;
        display: none;
        width: 130px;
        border: 1px solid #ededed;
        color: #1a1a1a;
        font-size: 12px;
        line-height: 12px;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        background: #fff;
        text-align: left;
        box-sizing: border-box;
        left: -42px;
        top: -42px;
        &:before {
            position: absolute;
            content: "";
            left: calc(50% - 3px);
            background: #fff;
            transform: rotate(-45deg);
            bottom: -5px;
            width: 6px;
            height: 6px;
            border-bottom: 1px solid #ededed;
            border-left: 1px solid #ededed;
        }
    }
}

.approving_block {
    margin-bottom: 30px;
    height: 80px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 8px 32px -8px rgba(0, 34, 24, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "GothaProMed", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    img {
        margin-right: 16px;
    }
}

.inactive_fields {
    pointer-events: none;
    opacity: 0.6;
}

.margin_bottom_20 {
    margin-bottom: 20px !important;
}

.form_video_wrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    .radio_wrapper {
        display: flex;
        margin: 10px 0 16px 0;
        label {
            display: flex;
            align-items: center;
            font-size: 12px;
            margin-right: 24px;
            cursor: pointer;
            user-select: none;
            input {
                margin-right: 6px;
                cursor: pointer;
                background-color: #ffffff !important;
                border: 1px solid #5ce6cd;
                border-radius: 10px;
                cursor: pointer;
                display: inline-block;
                height: 12px;
                position: relative;
                width: 12px;
                -webkit-appearance: none;
                &:checked:after {
                    display: block;
                    position: relative;
                    border-radius: 50%;
                    content: "";
                    background-color: #5ce6cd;
                    height: 6px;
                    width: 6px;
                    top: 2px;
                    left: 2px;
                }
            }
        }
    }
    .video_field {
        width: 100%;
        position: relative;
        textarea {
            box-sizing: border-box;
            height: 96px;
            width: 100%;
            border: 1px solid #ededed;
            resize: none;
            padding: 15px 20px;
            font-size: 12px;
            color: #1a1a1a;
            font-family: "GothaProReg", sans-serif;
            &::placeholder {
                font-family: "GothaProLig", sans-serif;
                color: #9b9b9b;
            }
        }
        .error {
            display: block;
            color: #d0021b;
            position: absolute;
            right: 0;
            bottom: -14px;
            font-size: 10px;
            line-height: 13px;
        }
        input {
            font-family: "GothaProReg", sans-serif;
            font-size: 14px;
            line-height: 13px;
            box-sizing: border-box;
            height: 50px;
            width: 100%;
            border: 1px solid #ededed;
            padding: 0 20px;
            &::placeholder {
                font-family: "GothaProLig", sans-serif;
                font-size: 12px;
            }
        }
    }
}

.payment_details_dialog {
    display: flex;
    flex-flow: column;
    .big_title {
        font-size: 32px;
        font-family: "GothaProBold", sans-serif;
    }
    .description {
        line-height: 16px;
        margin-top: 50px;
        font-size: 12px;
        font-family: "GothaProLig", sans-serif;
    }
    .description_bigger {
        margin: 50px 0 20px;
        line-height: 16px;
        font-size: 14px;
    }
    .payment_reason {
        background-color: rgba(69, 144, 232, 0.1);
        padding: 24px;
        width: calc(100% - 48px);
        margin-top: 50px;
        & > div {
            font-size: 14px;
            font-family: "GothaProMed", sans-serif;
            margin-bottom: 10px;
        }
        & > p {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .requisites_wrapper {
        margin: 40px 0 20px;
        border-top: 2px solid rgba(151, 151, 151, 0.2);
        padding-top: 40px;
        & > div {
            display: flex;
            align-items: flex-end;
            margin-bottom: 19px;
            & > p {
                font-size: 14px;
                line-height: 13px;
                font-family: "GothaProMed", sans-serif;
                margin-right: 16px;
            }
            & > span {
                line-height: 16px;
                font-size: 16px;
            }
        }
    }
    .requisites_payment_wrapper {
        margin: 24px 0 20px;
        & > div {
            display: flex;
            align-items: flex-end;
            margin-bottom: 19px;
            & > p {
                font-size: 14px;
                line-height: 13px;
                font-family: "GothaProMed", sans-serif;
                margin-right: 16px;
            }
            & > span {
                line-height: 16px;
                font-size: 16px;
            }
        }
    }
    .payment_details_form {
        box-sizing: border-box;
        .row {
            justify-content: space-between;
            .col {
                label {
                    font-family: "GothaProMed", sans-serif;
                }
                &:nth-child(1) {
                    width: 46% !important;
                }
                &:nth-child(2) {
                    width: 50% !important;
                    margin-left: 0 !important;
                }
                input[type="text"] {
                    width: 100% !important;
                    &::placeholder {
                        font-family: "GothaProLig", sans-serif;
                    }
                }
            }
        }
        .btn_wrapper {
            padding-top: 40px;
            margin-top: 40px;
            border-top: 2px solid rgba(151, 151, 151, 0.2);
            display: flex;
            justify-content: center;
            button {
                height: 50px;
                font-size: 14px;
                width: 190px;
                margin: 0 10px;
            }
        }
    }
}

.default_dialog {
    display: flex;
    flex-flow: column;
    .title {
        font-size: 32px;
        font-family: "GothaProBold", sans-serif;
    }
    .description {
        line-height: 16px;
        margin-top: 50px;
        font-size: 12px;
        font-family: "GothaProLig", sans-serif;
    }
    .btn_wrapper {
        padding-top: 40px;
        margin-top: 40px;
        border-top: 2px solid rgba(151, 151, 151, 0.2);
        display: flex;
        justify-content: center;
        button {
            height: 50px;
            font-size: 14px;
            width: 190px;
            margin: 0 10px;
        }
    }
    .choose_your_type {
        margin: 50px 0 30px;
        font-size: 12px;
        font-family: "GothaProMed", sans-serif;
        letter-spacing: 0;
        line-height: 12px;
    }
    .advertiser_types {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .type {
            user-select: none;
            cursor: pointer;
            height: 197px;
            width: 48%;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            box-shadow: 0 6px 24px -6px rgba(0, 34, 24, 0.15);
            border: 2px solid transparent;
            &.chosen_one {
                border: 2px solid #5ce6cd;
            }
            img {
                height: 80px;
                width: 80px;
                margin-bottom: 25px;
            }
            p {
                font-size: 14px;
                font-family: "GothaProMed", sans-serif;
                letter-spacing: 0;
                line-height: 13px;
            }
        }
    }
}

.delete_deal {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
        color: #1a1a1a;
        font-family: "GothaProMed", sans-serif;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 52px;
    }
    hr {
        height: 1px;
        width: 100%;
        border: 1px solid #979797;
        opacity: 0.2;
        box-sizing: border-box;
        margin: 40px 0;
    }
    .btn_wrapper {
        display: flex;
        align-items: center;
        button {
            width: 190px;
            &:first-of-type {
                margin-right: 20px;
            }
        }
    }
}
