.authentication-display{
  display: flex;
  >div{
    width: 50%;
    min-height: 100vh;
    &.box_bg{
      background-color: #5CE6CD;
      background-image: url("../../assets/image/bg_circles.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.aut_tabs{
  margin: 35px 0;
  display: flex;
  width: 100%;
  >button{
    text-transform: uppercase;
    margin-right: 50px;
    font-size: 14px;
    font-family: "GothaProBold",sans-serif;
    color: #9B9B9B;
    background: transparent;
    cursor: pointer;
  }
  .active{
    color: #1A1A1A;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      left: 0;
      bottom: -20px;
      height: 2px;
      width: 50px;
      background: #5CE6CD;
    }
  }

}

.authentication-content{
  width: 100%;
}

.form_auth{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  align-items: center;
  .auth_select {
    width: 100%;
    margin-bottom: 30px;
    height: 50px;
    .select_component {
      .select__control {
        background-color: white;
        .select__value-container {
          padding: 0 20px;
          color: #1A1A1A;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 13px;
          font-family: "GothaProReg", sans-serif;
        }
        .select__indicators {
          .select__indicator {
            padding: 0 20px;
          }
        }
      }
      .select__menu {
        border-radius: 0;
        box-shadow: none;
        border: 1px solid #EDEDED;
        .select__menu-list {
          font-size: 14px;
          font-family: "GothaProReg", sans-serif;
          .select__option{
            cursor: pointer;
          }
          .status_select{
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

.read_and_agreed {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 48px;
  img {
    cursor: pointer;
    margin: 4px 12px 0 0;
  }
  a {
    color: #1A1A1A;
    font-family: "GothaProMed",sans-serif;
    &:hover{
      text-decoration: none;
    }
  }
}

.authentication-form{
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 150px 0 75px;
  flex-flow: column;
  align-items: center;
  width: 420px;
  margin: 0 auto;
  .green_btn{
    margin-top: 20px;
  }
  .title_form{
    margin-bottom: 40px;
    font-size: 32px;
    font-family: "GothaProBold",sans-serif;
    line-height: 30px;
    width: 100%;
  }
  .info_aut{
    margin-top: 40px;
    font-size: 14px;
    line-height: 13px;
    span{
      padding-right: 10px;
    }
    a{
      color: #1A1A1A;
      font-family: "GothaProMed",sans-serif;
      &:hover{
        text-decoration: none;
      }
    }
  }
  .public_pages_links {
    margin-top: 40px;
    a{
      text-decoration: none;
      color: #979797;
      font-size: 12px;
      &:first-of-type {
        margin-right: 16px;
      }
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .forgot_pass{
    color: #1A1A1A;
    font-size: 14px;
    font-family: "GothaProMed",sans-serif;
    margin-top: 10px;
    margin-bottom: 30px;
    &:hover{
      text-decoration: none;
    }
  }
  .text_info{
    padding-bottom: 40px;
    width: 100%;
    font-size: 14px;
  }
  .box_icon_aut{
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    &:before{
      content: "";
      position: absolute;
      height: 4px;
      width: 100px;
      right: -150px;
      background:  #5CE6CD;
      top:calc(50% - 2px);
    }
    &:after{
      content: "";
      position: absolute;
      height: 4px;
      width: 100px;
      top:calc(50% - 2px);
      left: -150px;
      background:  #5CE6CD;
    }
  }
  .fourth_step{
    .box_icon_aut{
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 60px;
      &:before{
        content: "";
        position: absolute;
        height: 4px;
        width: 100px;
        right: -150px;
        background:  #5CE6CD;
        bottom: 45px;
        top: auto;
      }
      &:after{
        content: "";
        position: absolute;
        height: 4px;
        width: 100px;
        top: auto;
        bottom: 45px;
        left: -150px;
        background: #5CE6CD;
      }
    }
    .green_btn, .green_btn_big{
      margin-top: 75px;
    }
  }
}

.auth_error {
  color: #ff0000;
  font-size: 14px;
  text-align: center;
  position: absolute;
  bottom: -24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  span {
    color: #A1AEB7;
    line-height: 14px;
    font-size: 12px;
  }
  img {
    margin-right: 7px;
  }
}


