.snack_root {
  margin-bottom: 20px;
  &.error_snack {
    div {
      justify-content: center;
      background-color: #ffffff;
      font-family: "GothaProReg",sans-serif;
      color: #1A1A1A;
      svg {
        fill: #ffffff;
      }
    }
  }
}