.about_channel_blogger_wrapper{
  .block_wrapper_bg{
    padding: 30px;
    margin-bottom: 20px;
  }
  .channel_info_wrapper{
    .title{
      padding-bottom: 30px;
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    }
    .wrapper_btn{
      border-top: 1px solid rgba(151, 151, 151, 0.2);
      display: flex;
      justify-content: flex-end;
      padding-top: 30px;
      button{
        height: 40px;
        width: 150px;
        font-size: 10px;
      }

    }
  }
  .transaction_statistics{
    display: flex;
    flex-flow: column;
    .title{
      padding-bottom: 30px;
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    }
    .transaction_statistics_info{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 30px;
      >div{
        display: flex;
        flex-flow: column;
        width: 33.3%;
        >div{
          font-family: "GothaProMed",sans-serif;
          font-size: 12px;
          padding-bottom: 12px;
        }
        >span{
          font-family: "GothaProLig",sans-serif;
          font-size: 14px;
        }
      }
    }
  }
}

.changes_dialog{
  display: flex;
  flex-flow: column;
  align-items: center;
  img{
    margin-bottom: 32px;
    margin-top: 30px;
  }
  .title{
    font-family: "GothaProMed",sans-serif;
    font-size: 24px;
  }
  .btn_wrapper{
    padding-top: 40px;
    margin-top: 40px;
    border-top: 2px solid rgba(151, 151, 151, 0.2);
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 10px;
    button{
      height: 50px;
      width: 190px;
    }
  }
}

.wrapper_info_channel{
  padding: 30px 0;
  display: flex;
  flex-flow: column;
  >div:first-of-type{
    padding-bottom: 45px;
  }
  >div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    >div{
      width: 33.3%;
      display: flex;
      flex-flow: column;
      .descriptions{
        font-family: "GothaProMed",sans-serif;
        font-size: 12px;
        padding-bottom: 12px;
      }
      p{
        font-family: "GothaProLig",sans-serif;
        font-size: 14px;
      }
    }
  }
}

.wrapper_checkbox{
  display: flex;
  align-items: center;
  user-select: none;
  .checkbox:first-of-type{
    margin-right: 32px;
  }
  .checkbox{
    label{
      font-size: 14px;
      font-family: "GothaProLig",sans-serif;
      cursor: pointer;
      /*input {
        margin-right: 6px;
        cursor: pointer;
        background-color: #FFFFFF;
        border: 1px solid #5CE6CD;
        border-radius: 10px;
        cursor: pointer;
        display: inline-block;
        height: 12px;
        position: relative;
        width: 12px;
        -webkit-appearance: none;
        &:checked:after {
          display: block;
          position: relative;
          border-radius: 50%;
          content: '';
          background-color: #5CE6CD;;
          height: 6px;
          width: 6px;
          top: 2px;
          left: 2px;
        }
      }*/
    }
  }
}

.MuiMenu-paper{
  max-height: 280px!important;
  margin-top: 2px;
  margin-left: -1px;
  box-shadow: none!important;
  border: 1px solid #EDEDED;
  border-radius: 0!important;
  &::-webkit-scrollbar {
    width: 14px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    margin: 10px;
    border-radius: 2px;
    margin-right: 5px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background: #edf2f8;
    border-right: 5px solid;
    border-left: 5px solid;
    border-color: white;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #5CE6CD;
  }
  ul{
    max-height: 280px!important;
    width: 100%;
    box-sizing: border-box;
    padding: 0!important;
    li{
      padding: 6px 12px!important;
      max-height: 100%!important;
      background: transparent!important;
      &:hover{
        background: transparent!important;
      }
      >span{
        padding: 0;
        &:hover{
          background: transparent;
        }
        svg{
          display: inline-block;
          position: relative;
          cursor: pointer;
          padding-left: 0;
          padding-right: 0;
          border: 1px solid #979797;
          background-color: #FFFFFF;
          color: #2CC197;
          height: 12px;
          width: 12px;
          margin: 0px 7px 0 0;
          /*path{
            display: none;
          }*/
        }
        input{
          opacity: 1;
        }
        input[type=checkbox]:hover + svg  {
          box-shadow: none!important;
        }
        input[type=checkbox] + svg path {
          d: none;
        }
        input[type=checkbox]:checked + svg path {
          d: path("M 19 3 H 5 c -1.11 0 -2 0.9 -2 2 v 14 c 0 1.1 0.89 80 0 -16 h 30 c 1.11 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.89 -2 -2 -2 Z m -9 14 l -5 -5 l 1.41 -1.41 L 10 14.17 l 7.59 -7.59 L 19 8 l -9 9 Z");
        }
        input[type=checkbox]:checked + svg path {
          content: "";
          display: block;
          width: 3px;
          height: 5px;
          border: solid #2CC197;
          border-width: 0 1px 1px 0;
          /*-webkit-transform: rotate(45deg);
          transform: rotate(45deg);*/
          position: absolute;
          top: 2px;
          left: 4px;
        }
        input[type=checkbox]:checked + svg {
          border-color: #2CC197;
        }

        input[type=checkbox]:hover + svg {
          box-shadow:0px 0px 5px #2CC197;
        }
      }
      >span:first-of-type{

      }
      >div{
        display: flex;
        margin: 0!important;
        span{
          color: #1A1A1A;
          font-family: "GothaProReg",sans-serif;
          font-size: 10px;
        }
      }
    }
  }
}
.line_check{
  margin: 6px 0!important;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2)!important;
}
