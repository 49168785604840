.documents_block{
  display: flex;
  padding: 40px 0;
  border-bottom: 2px solid rgba(151, 151, 151, 0.2);
  flex-flow: column;
  .title{
    font-family: "GothaProMed",sans-serif;
    font-size: 14px;
    line-height: 13px;
    padding-bottom: 20px;
  }
  .documents {
    display: flex;
    flex-flow: column;
    img {
      margin-right: 8px;
    }
    a {
      cursor: pointer;
      color: #4A90E2;
      font-family: "GothaProMed", sans-serif;
      font-size: 12px;
      margin-bottom: 16px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    p {
      user-select: none;
      cursor: pointer;
      margin-top: 14px;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
  }
}
