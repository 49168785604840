.stepper_block{
  padding: 40px 0;
  border-bottom: 2px solid rgba(151, 151, 151, 0.2);
  .btn_wrapper {
    display: flex;
    margin-top: 84px;
    &.without_buttons {
      margin-top: 50px;
    }
    button {
      font-size: 10px;
      width: 150px;
      height: 40px;
      margin-right: 20px;
    }
  }
}

.stepper_dialog{
  display: flex;
  flex-flow: column;
  .box_icon{
    display: flex;
    justify-content: center;
  }
  .big_title {
    font-size: 32px;
    font-family: "GothaProBold", sans-serif;
  }
  .block_info_forms {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
    .error {
      color: #D0021B !important;
      font-size: 10px!important;
      font-family: "GothaProMed", sans-serif !important;
      right: 0!important;
      bottom: -17px;
      top: unset!important;
    }
    .Mui-error {
      right: 0!important;
    }
  }
  .description {
    line-height: 16px;
    margin: 50px 0 20px;
    font-size: 12px;
    font-family: "GothaProLig", sans-serif;
  }
  .title{
    display: flex;
    justify-content: center;
    font-family: "GothaProMed",sans-serif;
    font-size: 24px;
    line-height: 24px;
    padding-top: 32px;
  }
  .btn_wrapper{
    padding-top: 40px;
    margin-top: 40px;
    border-top: 2px solid rgba(151, 151, 151, 0.2);
    display: flex;
    justify-content: center;
    button{
      height: 50px;
      font-size: 14px;
      width: 190px;
      margin: 0 10px;
    }
  }
}

.tooltip_wrapper{
  position: relative;
  &:hover{
    .tooltip_block{
      display: block;
    }
  }
  .tooltip_block{
    display: none;
    position: absolute;
    left: -179px;
    bottom: -58px;
    padding: 12px 15px;
    width: 380px;
    border: 1px solid #EDEDED;
    background: #fff;
    text-align: center;
    box-sizing: border-box;
    font-size: 12px;
    z-index: 1001;
    &:before{
      position: absolute;
      content: "";
      left: calc(50% - 3px);
      top: -5px;
      width: 6px;
      height: 6px;
      background: #fff;
      z-index: 10;
      transform :rotate(45deg);
      border-left: 1px solid #EDEDED ;
      border-top: 1px solid #EDEDED ;
    }
    p{
      display: inline-block;
      font-family: "GothaProMed",sans-serif;
    }

  }
}

.panel_stepper{
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 40px;
  .btn_block{
    display: flex;
    align-items: center;
    .separator {
      height: 12px;
      width: 1px;
      background-color: #979797;
      margin: 0 20px;
    }
    .tooltip {
      top: -60px!important;
      &:before{
        top: unset!important;
        bottom: -5px;
        transform: rotate(-45deg)!important;
      }
    }
  }
  .text{
    display: flex;
    align-items: center;
    p{
      font-size: 16px;
      line-height: 16px;
    }
    span{

      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      padding-right: 15px;
      line-height: 13px;
    }
  }
  button{
    margin-left: 20px;
    height: 40px;
    width: 150px;
    font-size: 10px;
  }
}

.deadline{
  display: flex;
  align-items: center;
  span{
    color: #979797;
    font-size: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    &.guarantor {
      padding-right: 0;
    }
    img{
      margin-right: 6px;
    }
  }
  p{
    font-family: "GothaProMed",sans-serif;
    font-size: 14px;
    line-height: 13px;
  }
  .box_tooltip{
    margin: 0 12px 2px 4px;
    cursor: pointer;
    position: relative;
    &:hover{
      .tooltip {
        display: flex;
      }
    }
    .tooltip {
      cursor: context-menu;
      font-family: "GothaProLig",sans-serif;
      flex-flow: column;
      position: absolute;
      display: none;
      width: 320px;
      border: 1px solid #EDEDED;
      color: #1A1A1A;
      font-size: 12px;
      line-height: 12px;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      background: #fff;
      text-align: center;
      box-sizing: border-box;
      left: -158px;
      top: 20px;
      &:before{
        position: absolute;
        content: "";
        left: calc(100% - 157px);
        background: #fff;
        transform: rotate(140deg);
        top: -5px;
        width: 6px;
        height: 6px;
        border-bottom: 1px solid #EDEDED;
        border-left: 1px solid #EDEDED;
      }
    }
  }
}

.stepper{
  display: flex;
  align-items: center;
  width: 100%;
  >div:nth-child(2){
    span{
      left: -45px;
    }
  }
  >div:nth-child(3){
    span{
      bottom: -37px;
      left: -40px;
    }
  }
  >div:nth-child(4){
    span{
      bottom: -37px;
      left: -37px;
      max-width: 100px;
    }
  }
  >div:nth-child(5){
    span{
      left: -24px;
    }
  }
  >div:nth-child(6){
    span{
      bottom: -37px;
      left: -42px;
    }
  }
  >div:nth-child(7){
    span{
      bottom: -37px;
      left: -42px;
    }
  }
  >div:nth-child(8){
    span{
      left: -20px;
    }
    &:before{
      display: none;
    }
  }

  .date{
    font-size: 12px;
    &:first-of-type{
      position: relative;
      min-width: 124px;
      width: 8%;
      &:before{
        position: absolute;
        content: "";
        height: 2px;
        width: 44%;
        top: 5px;
        right: 2px;
        border-top: 2px dotted #5CE6CD;
      }
    }
    &:last-of-type{
      background: #ffffff;
      z-index: 1;
      margin-left: -70px;
      min-width: 124px;
      width: 8%;
    }
  }
  .step{
    width: 12%;
    position: relative;
    img{
      width: 24px;
      height: 24px;
      background-size: 100% 100%;
    }
    &:before{
      position: absolute;
      content: "";
      height: 2px;
      width: 87%;
      right: 0;
      top: 11px;
    }
    span{
      position: absolute;
      left: 0;
      bottom: -23px;
      font-size: 12px;
      max-width: 110px;
      text-align: center;
      line-height: 14px;
    }
  }
  .completed {
    &:before{
      display: unset!important;
      position: absolute;
      content: "";
      height: 2px;
      width: 44%;
      top: 11px;
      left: 26px;
      border-top: 2px dotted #5CE6CD;
    }
  }
  .inactive{
    span{
      color: #979797;
    }
    &:before{
      background: #EDEDED;
    }
    &:after{
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      border: 2px solid #EDEDED;
      z-index: 10;
      background: #fff;
      left: 0px;
      top: 0px;
      border-radius: 50%;
    }
  }
  .error{
    span {
      max-width: 100px;
      left: -38px !important;
      bottom: -37px!important;
    }
    &:before{
      background: #EDEDED;
    }
    &:after{
      position: absolute;
      content: "";
      background-image: url("../../../assets/image/failed_icon.png");
      height: 24px;
      width: 24px;
      z-index: 10;
      left: 0px;
      top: 0px;
      border-radius: 50%;
    }
    .tooltip_block{
      display: none;
    }
  }
  .waiting{
    &:after{
      position: absolute;
      content: "";
      height: 28px;
      width: 28px;
      border: 2px solid #5CE6CD;
      z-index: 10;
      background: #fff;
      left: -4px;
      top: -4px;
      border-radius: 50%;
    }
    &:before{
      background: #EDEDED;
    }
  }

  .active{
    &:before{
      background: #5CE6CD;
    }
  }

}