.add_channel_wrapper{
  h1{
    padding-bottom: 50px;
  }
  .add_channel{
    box-shadow: 0 8px 32px -8px rgba(0,34,24,0.15);
    padding: 100px 0 80px 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    .title{
      padding-top: 64px;
      padding-bottom: 16px;
      font-family: "GothaProMed",sans-serif;
      font-size: 24px;
      line-height: 24px;
    }
    .descriptions{
      font-size: 14px;
      line-height: 16px;
    }
    span{
      height: 1px;
      width: 50px;
      background: #5CE6CD;
      margin: 30px 0;
      display: flex;
    }
    button{
      height: 50px;
      width: 370px;
    }
  }


}