.feedback_block{
  display: flex;
  flex-flow: column;
  padding: 40px 0;
  border-bottom: 2px solid rgba(151, 151, 151, 0.2);
  .title{
    padding-bottom: 20px;
    font-family: "GothaProMed",sans-serif;
    font-size: 14px;
  }
  .take_feedback{
    div{
      padding-bottom: 24px;
      color: #979797;
      font-family: "GothaProLig",sans-serif;
      font-size: 12px;
      line-height: 16px;
    }
    button{
      width: 150px;
      font-size: 10px;
      height: 40px;
    }
  }
}

.leave_feedback{
  .rating_wrapper {
    cursor: pointer;
  }
  .title{
    font-family: "GothaProBold",sans-serif;
    font-size: 32px;
    line-height: 30px;
    padding-bottom: 50px;
  }
  .descriptions{
    font-family: "GothaProMed",sans-serif;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    padding-bottom: 6px;
  }
  .rating_wrapper{
    span{
      cursor: pointer;
      font-size: 42px!important;
    }
  }
  .commends{
    span{
      font-family: "GothaProMed",sans-serif;
      font-size: 12px;
      line-height: 12px;
      padding-bottom: 16px;
      display: flex;
      padding-top: 24px;
    }
    textarea{
      border: 1px solid #EDEDED;
      padding: 16px 20px ;
      box-sizing: border-box;
      width: 100%;
      height: 95px;
      resize: none;
      margin-bottom: 30px;
      font-size: 12px;
    }
  }
  .col-checkbox{
    user-select: none;
    label{
      font-size: 12px;
      line-height: 16px;
      align-items: center;
      display: flex;
      &:before{
        margin-top: 0;
      }
    }
  }
  .btn_wrapper_block{
    border-top: 2px solid rgba(151, 151, 151, 0.2);
    padding-top: 40px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .border_btn_green{
      height: 50px;
      width: 190px;
      font-size: 14px;
    }
  }
}

.rating_block_info{
  display: flex;
  flex-flow: column;
  .rating_block{
    display: flex;
    align-items: center;
  }
  p{
    color: #9B9B9B;
    font-family: "GothaProLig",sans-serif;
    font-size: 10px;
    line-height: 10px;
    padding-left: 12px;
    margin-top: 5px;
  }
  .rating_wrapper{
    span{
      cursor: pointer;
      font-size: 25px!important;
    }
  }
  .descriptions{
    padding-top: 10px;
    font-family: "GothaProLig",sans-serif;
    font-size: 12px;
    line-height: 12px;
  }
}