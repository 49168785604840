.about_channel_wrapper{
  margin-bottom: 45px;
  .block_wrapper_bg{
    padding: 30px;
    margin-bottom: 20px;
  }
  .title{
    font-family: "GothaProMed",sans-serif;
    font-size: 14px;
    line-height: 13px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  }
  .info{
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    >div{
      display: flex;
      flex-flow: column;
      width: 33.3%;
      span{
        font-family: "GothaProMed",sans-serif;
        font-size: 12px;
        line-height: 12px;
        padding-bottom: 12px;
      }
      p{
        font-family: "GothaProLig",sans-serif;
        font-size: 14px;
      }
    }
  }
  .info_channel_first{
    padding: 30px 0 50px 0;
  }
  .info_channel{
    display: flex;
    >div{
      span{
        font-family: "GothaProMed",sans-serif;
        font-size: 12px;
        line-height: 12px;
        padding-bottom: 12px;
        display: flex;
      }
      .info_text{
        font-family: "GothaProLig",sans-serif;
        font-size: 14px;
        line-height: 13px;
      }
      .info_text_regular{
        font-family: "GothaProReg",sans-serif;
        font-size: 14px;
        line-height: 13px;
      }
    }
    >div:first-of-type{
      width: 50%;
      padding-right: 30px;
    }
    .tegs{
      display: flex;
      p{
        text-transform: uppercase ;
        background: rgba(92,230,205,0.5);
        padding: 5px 6px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "GothaProMed",sans-serif;
        font-size: 10px;
      }
    }
  }

}