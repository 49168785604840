.statistics_wrapper {
  .block_wrapper_bg {
    padding: 40px 50px;
    .statistics_title {
      color: #1A1A1A;
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      width: 100%;
      border-bottom: 1px solid rgba(9, 151, 151, 0.2);
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
    .subscribers_chart {
      margin-bottom: 60px;
    }
  }
}