.campaing_page{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  .info_users{
    display: flex;
    flex-flow: column;
    .name{
      font-size: 16px;
      font-family: "GothaProMed",sans-serif;
      padding-bottom: 8px;
    }
    .status{
      display: flex;
      .info_status{
        color: #9B9B9B;
        font-size: 12px;
        display: flex;
        align-items: center;

        span{
          height: 8px;
          width: 8px;
          border-radius: 50%;
          margin-right: 8px;
        }
        .paused{
          background: #F0C57D;
        }
        .active{
          background: #2CC197;
        }
      }
      a{
        color: #4A90E2;
        font-size: 12px;
        margin-left: 20px;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  .menu_compaing{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .delete_btn{
      margin-right: 32px;
      background: transparent;
      cursor: pointer;
    }
    .activated{
      margin-right: 20px;
      background: transparent;
      cursor: pointer;
    }
    .balance{
      display: flex;
      padding-right: 32px;
      div{
        font-family: "GothaProLig",sans-serif;
        font-size: 12px;
        padding-right: 8px;
      }
      span{
        font-family: "GothaProMed",sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        span{
          font-family: "Roboto", sans-serif;
          font-weight: 500;
        }
      }
    }
    .border_btn_green{
      height: 32px;
      width: 136px;
      font-family: "GothaProBold",sans-serif;
      font-size: 10px;
      background: rgba(92, 230, 205, 0.25);
      border-color: transparent ;
    }
  }
}

.descriptions_projects{
  padding-bottom: 30px;
  border-bottom: 2px solid rgba(151, 151, 151, 0.2);
  display: flex;
  flex-flow: column;
  margin-bottom: 40px;
  .title{
    font-family: "GothaProMed",sans-serif;
    font-size: 14px;
    line-height: 13px;
    padding-bottom: 21px;
  }
  .age_range {
    font-family: "GothaProLig",sans-serif;
    font-size: 12px;
    margin-bottom: 30px;
    img {
      height: 17px;
      margin-right: 12px;
    }
  }
  .text{
    max-height: 112px;
    color: #1A1A1A;
    font-family: "GothaProLig",sans-serif;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;
    word-break: break-word;
    &:before{
      position: absolute;
      content: "";
      height: 18px;
      left: 0;
      bottom: 0;
      box-shadow: inset -1px -18px 26px 10px rgba(255, 255, 255, 0.8);
      width: 100%;
    }

  }
  .full_height{
    max-height: 100%;
    &:before{
      display: none;
    }
  }
  button{
    background: transparent;
    cursor: pointer;
    color: #4A90E2;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    .up{
      background: url("../../assets/image/arrow_up.png");
      width: 6px;
      height: 2px;
      margin-right: 5px;
      display: flex;
      align-items: center;
    }
    .down{
      background: url("../../assets/image/arrow_up.png");
      width: 6px;
      height: 2px;
      margin-right: 5px;
      display: flex;
      align-items: center;
      transform: rotate(180deg);
    }

  }
}

.add_task{
  .title{
    font-family: "GothaProBold",sans-serif;
    font-size: 32px;
    padding-bottom: 50px;
  }
  .descriptions{
    font-family: "GothaProMed",sans-serif;
    font-size: 12px;
    padding-bottom: 5px;
  }
  .advertisement{
    display: flex;
    //justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 2px solid rgba(151, 151, 151, 0.2);
    >div{
      border: 2px solid transparent;
      padding: 25px;
      width: 230px;
      box-sizing: border-box;
      box-shadow: 0 6px 24px -6px rgba(0,34,24,0.15);
      cursor: pointer;
      min-height: 145px;
      margin: 25px 12px 0 0;
      &:nth-child(3) {
        margin-right: 0!important;
      }
      .info_title{
        font-family: "GothaProMed",sans-serif;
        font-size: 14px;
        padding-bottom: 15px;
        line-height: 19px;
      }
      .info_descriptions{
        font-family: "GothaProLig",sans-serif;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .active{
      border: 2px solid #5CE6CD;
    }
  }
  .btn_wrapper{
    display: flex;
    justify-content: center;
    button{
      height: 50px;
      font-size: 14px;
      width: 190px;
      margin: 0 10px;
    }
  }
}

.create_task{
  .title{
    font-family: "GothaProBold",sans-serif;
    font-size: 32px;
    line-height: 30px;
    padding-bottom: 20px;
  }
  .label_box{
    display: flex;
    flex-flow: column;
    margin-top: 30px;
    position: relative;
    span{
      padding-bottom: 15px;
      font-family: "GothaProMed",sans-serif;
      font-size: 12px;
      line-height: 12px;
    }
    .box{
      width: 100%;
      border: 1px solid #EDEDED;
      padding: 0px 20px;
      height: 60px;
      align-items: center;
      display: flex;
      margin-bottom: 30px;
      a{
        cursor: pointer;
        box-sizing: border-box;
        color: #4A90E2;
        text-decoration: underline;
      }
    }
    textarea{
      resize: none;
      height: 96px;
      width: 100%;
      font-size: 12px;
      border: 1px solid #EDEDED;
      padding: 15px 20px ;
      &::placeholder{
        font-family: "GothaProLig",sans-serif;
        color: #9B9B9B;
      }
    }
    .error {
      display: block;
      color: #D0021B;
      position: absolute;
      right: 0;
      bottom: -31px;
      font-size: 10px;
      line-height: 13px;
    }
    .link-textarea {
      color: #4A90E2;
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      padding: 22px 20px;
      border: 1px solid #EDEDED;
      &::placeholder{
        color: #979797;
        font-family: "GothaProLig",sans-serif;
      }
    }
    .select_wrapper{
      height: 50px;
      .select_component .select__control{
        border: 1px solid #EDEDED;
        background: #ffffff;
        .select__value-container{
          padding: 2px 8px 2px 20px;
          font-size: 12px;
          font-family: "GothaProReg",sans-serif;
        }
      }
      .select__indicator {
        padding: 8px 19px;
      }
    }
  }
  .value_info{
    position: relative;
    width: 145px;
    margin: 30px 0;
    .error {
      display: block;
      color: #D0021B;
      position: absolute;
      right: 0;
      bottom: -31px;
      font-size: 10px;
      line-height: 13px;
    }
    span{
      color: #1A1A1A;
      font-family: "GothaProMed",sans-serif;
      font-size: 12px;
      line-height: 12px;
      padding-bottom: 16px;
      display: flex;
    }
    input{
      box-sizing: border-box;
      height: 50px;
      width: 145px;
      border: 1px solid #EDEDED;
      font-size: 12px;
      font-family: "GothaProReg",sans-serif;
      padding: 0 50px 0 20px ;
      &::placeholder{
        color: #979797;
        font-family: "GothaProLig",sans-serif;
      }
    }
    p{
      position: absolute;
      right: 20px;
      color: #9B9B9B;
      font-family: "GothaProLig",sans-serif;
      font-size: 12px;
      line-height: 12px;
      top:calc(50% - -8px);
    }
  }
  .btn_wrapper{
    display: flex;
    justify-content: center;
    padding-top: 40px;
    border-top: 2px solid rgba(151, 151, 151, 0.2);
    margin-top: 40px;
    position: relative;
    button{
      height: 50px;
      font-size: 14px;
      width: 190px;
      margin: 0 10px;
    }
    .error {
      display: block;
      color: #D0021B;
      position: absolute;
      right: 158px;
      bottom: -16px;
      font-size: 10px;
      line-height: 13px;
    }
  }

  .form_task{
    .col-checkbox{
      align-items: center;
      display: flex;
      user-select: none;
      label{
        color: #1A1A1A;
        font-family: "GothaProReg",sans-serif;
        font-size: 12px;
        line-height: 16px;
        display: flex;
      }
    }
    .first_block{
      padding-bottom: 15px;
    }
  }
}