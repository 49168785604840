.app_head {
  background:rgba(92, 230, 205, 0.5);
  a {
    text-decoration: none;
  }
}

.header_wrapper{
  user-select: none;
  height: 80px;
  display: flex;
  justify-content: space-between;
}

.block_logo{
  background: #5CE6CD;
  display: flex;
  justify-content:center;
  align-items: center;
  width: 260px;
  height: 100%;
}

.head_options{
  align-items: center;
  display: flex;
  margin-right: 50px;
}

.header_wrapper{
  .balance{
    margin-right: 24px;
    font-size: 14px;
    font-family: "GothaProMed",sans-serif;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
  .replenish_btn{
    text-transform: uppercase;
    margin-right: 50px;
    background:rgba(26, 26, 26, 0.1);
    height: 32px;
    width: 98px;
    color: #1A1A1A;
    font-family: "GothaProBold",sans-serif;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      background:rgba(26, 26, 26, 0.2);

    }
  }
  .notifications_wrapper{
    position: relative;

  }
  .notifications_block{
    background: transparent;
    margin-right: 24px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      background:rgba(26, 26, 26, 0.2);

    }
  }
  .block_drop_down{
    display: flex;
    cursor: pointer;
    .text_block{
      align-items: flex-end;
      display: flex;
      flex-flow: column;
      div{
        font-family: "GothaProMed",sans-serif;
        font-size: 16px;
      }
      span{
        font-family: "GothaProLig",sans-serif;
        font-size: 12px;
      }
    }
    .arrow_block{
      padding: 0 10px;
      align-items: center;
      display: flex;
      & > img {
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }
    }
    .active_arrow{
      transform: rotate(180deg);
    }
  }
  .menu_wrapper{
    display: flex;
    position: relative;

    .open_block{
      img{
        transform: rotate(180deg);

      }
    }
    .menu{
      z-index: 10;
      position: absolute;
      right: 0;
      top: 40px;
      padding: 16px;
      background: #fff;
      border: 1px solid #EDEDED;
      width: 190px;
      & > a, & > div {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #1A1A1A;
        font-size: 12px;
        img{
          margin-right: 8px;
        }
      }
      hr{
        height: 1px;
        display: flex;
        width: 100%;
        background: rgba(151, 151, 151, 0.2);
        margin-bottom: 15px;
      }
      button{
        color: #979797;
        font-size: 12px;
        display: flex;
        align-items: center;
        background: transparent;
        cursor: pointer;
        img{
          margin-right: 8px;
        }
      }
    }
  }
}

.notifications_info_wrapper{
  position: absolute;
  right: 24px;
  top: 36px;
  box-sizing: border-box;
  max-height: 259px;
  width: 500px;
  border: 1px solid #EDEDED;
  background-color: #FFFFFF;
  z-index: 10;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;

    background: #EDF2F8;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #5CE6CD;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #5CE6CD;
  }
  >div{
    padding: 15px 20px;
    position: relative;
    p{
      font-family: "GothaProBold",sans-serif;
      padding: 0 3px;
    }
    &:first-of-type {
      padding-top: 20px;
    }
  }
  >div:last-of-type{
    border: none;
  }
  .status_notifications{
    display: flex;
    align-items: center;
    color: #1A1A1A;
    font-size: 12px;
    line-height: 12px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    cursor: pointer;
    &:after {
      position: absolute;
      content: '';
      border-bottom: 1px solid rgba(151, 151, 151, 0.2);
      width: calc(100% - 40px);
      transform: translateX(-50%);
      bottom: 0;
      left: 50%;
    }
    &:hover {
      background-color: #EDEDED;
    }
    span{
      height: 8px;
      width: 8px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .email_icon {
      margin-right: 8px;
    }
    .balance_icon {
      margin-right: 6px;
    }
  }
  .messenger{
    display: flex;
    align-items: center;
    color: #1A1A1A;
    font-size: 12px;
    line-height: 12px;
    img{
      margin-right: 10px;
      margin-top: -1px;
    }
  }
  .no_items{
    padding: 20px 0;
    color: #979797;
    font-size: 12px;
    line-height: 12px;
    margin-left: 20px;
    button {
      background: transparent;
      color: #4A90E2;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

