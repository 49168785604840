.search_tasks_wrapper {
    display: flex;
    flex-flow: column;
    .tasks_wrapper {
        margin-bottom: 30px;
        padding: 30px;
        box-sizing: border-box;
        box-shadow: 0 8px 32px -8px rgba(0, 34, 24, 0.15);
        display: flex;
        flex-flow: column;
    }
    .tasks {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 30px;
    }
    .info_title {
        > span {
            font-family: "GothaProMed", sans-serif;
            font-size: 16px;
            line-height: 16px;
            padding-bottom: 8px;
            display: flex;
        }
        > div {
            display: flex;
            a {
                color: #4a90e2;
                font-size: 12px;
                line-height: 12px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            span {
                margin: 0 15px;
                box-sizing: border-box;
                height: 12px;
                width: 1px;
                background: #1a1a1a;
            }
        }
        .about_campaign {
            color: #4a90e2;
            font-family: "GothaProReg", sans-serif;
            font-size: 12px;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear;
            -ms-transition: all 0.3s linear;
            -o-transition: all 0.3s linear;
            transition: all 0.3s linear;
            &:hover {
                opacity: 0.7;
                cursor: pointer;
            }
        }
    }
    .data {
        font-size: 12px;
        line-height: 12px;
    }
    .sponsored_info {
        display: flex;
        flex-flow: column;
        padding-bottom: 24px;
        div {
            padding-bottom: 12px;
            font-family: "GothaProMed", sans-serif;
            font-size: 12px;
            line-height: 12px;
        }
        a {
            width: fit-content;
            color: #4a90e2;
            font-family: "GothaProMed", sans-serif;
            font-size: 14px;
            line-height: 13px;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .descriptions_projects {
        margin-bottom: 30px;
        .title {
            font-size: 12px;
            padding-bottom: 12px;
        }
    }
    .tasks_wrapper_btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .descriptions {
            font-size: 14px;
            line-height: 13px;
        }
        .price_info {
            display: flex;
            align-items: center;
            div {
                margin-right: 30px;
                font-family: "GothaProMed", sans-serif;
                font-size: 14px;
                line-height: 13px;
            }
            button {
                height: 32px;
                width: 136px;
                font-size: 10px;
                font-family: "GothaProBold", sans-serif;
                background: rgba(92, 230, 205, 0.25);
                border-color: transparent;
            }
        }
    }
}

.dg_campaign_info_wrapper {
    width: 100%;
    display: flex;
    flex-flow: column;
    color: #1a1a1a;
    .dg_campaign_info_title {
        font-family: "GothaProBold", sans-serif;
        font-size: 32px;
        margin-bottom: 40px;
    }
    .dg_campaign_info_label {
        font-family: "GothaProMed", sans-serif;
        font-size: 14px;
        margin-bottom: 16px;
    }
    .dg_campaign_info_text {
        font-family: "GothaProLig", sans-serif;
        margin-bottom: 30px;
        font-size: 12px;
        word-break: break-word;
        img {
            width: 10px;
            margin-right: 12px;
        }
    }
}

.block_tasks_bloger.search {
    .blogers_select_wrapper {
        width: 100%;
        margin-top: 20px;
        height: 50px;
        .select__control {
            background-color: #fff;
            font-size: 14px;
        }
    }
    .block_info_forms {
        border-top: none;
        justify-content: flex-start;
        margin-top: 0;
        padding-top: 20px;
        padding-bottom: 0;
        .price {
            &:first-child {
                margin-right: 30px;
            }
        }
    }
}
