.task_block{
  display: flex;
  padding: 40px 0;
  border-bottom: 2px solid rgba(151, 151, 151, 0.2);
  flex-flow: column;
  .info_title{
    padding-bottom: 30px;
    div{
      padding-bottom: 8px;
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      line-height: 13px;
    }
    p{
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .descriptions_projects{
    border: none;
    padding: 0;
    margin: 0;
    .title{
      padding-bottom: 12px;
    }
  }
  .link_advertising{
    padding-bottom: 24px;
    div{
      font-family: "GothaProMed",sans-serif;
      padding-bottom: 12px;
      font-size: 12px;
      line-height: 12px;
    }
    a{
      color: #4A90E2;
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      line-height: 13px;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}