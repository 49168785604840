
.profile-advertiser{
  h1{
    padding-bottom: 50px;
  }
  &  p {
    color: #1A1A1A;
    font-family: "GothaProBold", sans-serif;
    font-size: 14px;
    line-height: 13px;
    padding-bottom: 30px;
  }
  button{
    width: 190px;
    height: 50px;
  }
  .btn-change-password{
    text-decoration: none;
    color: #4A90E2;
    font-family: "GothaProBold", sans-serif;
    font-size: 14px;
    line-height: 13px;
    cursor: pointer;
    background: #FFFFFF;
    width: 120px;
    height: unset;
  }
}
.container-form{
  .row{
    //background: grey;
    display: flex;
    &{
      margin-bottom: 30px;
    }
    .col{
      width: 420px;
      //border: 1px solid black;
      label{
        display: block;
        color: #1A1A1A;
        font-family: "GothaProBold", sans-serif;
        font-size: 12px;
        line-height: 12px;
        padding-bottom: 10px;
      }
      input[type="text"]{
        height: 50px;
        width: 420px;
        color: #1A1A1A;
        font-size: 14px;
        line-height: 13px;
        border: 1px solid #EDEDED;
        padding: 20px;
        box-sizing: border-box;
        &:disabled {
          color: rgba(26, 26, 26, 0.6);
          background: transparent;
          cursor: not-allowed;
        }
        &::placeholder{
          color: #9B9B9B;
          font-size: 14px;
          font-family: "GothaProReg", sans-serif;
        }
      }
    }
    & > .col:nth-child(2){
      margin-left: 50px;
    }
    .col-checkbox{
      position: relative;
      margin-top: -13px;
      display: flex;
      user-select: none;
      label{
        font-family: "GothaProReg", sans-serif;
      }
    }
  }
  hr{
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px solid #979797;
    opacity: 0.2;
    margin: 39px 0;
  }
}



.dialog_wrapper{
  & > .change-password {
    h1{
      font-family: "GothaProBold", sans-serif;
    }
    .row{
      display: flex;
      flex-direction: column;
      .col{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        input{
          border: 1px solid #EDEDED;
          width: 720px;
          height: 50px;
          box-sizing: border-box;
          padding: 0 50px 0px 20px;
          font-size: 14px;
          background: url("../../assets/image/password_icon.png") no-repeat;
          background-position: right 20px center;
          &::placeholder{
            color: #9B9B9B;
            font-size: 14px;
            font-family: "GothaProReg", sans-serif;
          }
        }
        label{
          margin-bottom: 10px;
          color: #1A1A1A;
          font-family: "GothaProBold", sans-serif;
          font-size: 12px;
          line-height: 12px;
        }
      }
      & > .col:nth-child(2){
        margin-top: 50px;
      }
      hr{
        box-sizing: border-box;
        height: 1px;
        width: 720px;
        border: 1px solid #979797;
        opacity: 0.2;
        margin: 39px 0;
      }
      .dialog-button-wrapper{
        display: flex;
        justify-content: center;
        button{
          width: 190px;
          height: 50px;
          &:first-child{
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.success-change-password{
  display: flex;
  align-items: center;
  flex-direction: column;
  p{
    margin-top: 32px;
    font-size: 24px;
    font-family: "GothaProBold", sans-serif;
  }
  img{
    width: 160px;
    height: 160px;
  }
  button{
    height: 50px;
    width: 190px;
  }
  hr{
    box-sizing: border-box;
    height: 1px;
    width: 720px;
    border: 1px solid #979797;
    opacity: 0.2;
    margin: 39px 0;
  }
}


