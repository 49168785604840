.select_component {
  height: 100%;
  & ~ .date_wrapper {
    margin-left: 20px;
  }
  .select__control--is-focused {
    box-shadow: none;
    border: 1px solid #65A3E8;
    .indicator_active {
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }


  .select__control {
    height: 100%;
    background-color: #EDEDED;
    color: #1A1A1A!important;
    font-family: "GothaProMed",sans-serif!important;
    font-size: 10px;
    border: 1px solid #EDEDED;
    border-radius: 0;
    &:hover {
      cursor: pointer;
      border: 1px solid #EDEDED;

    }
    .select__placeholder {
      color: rgba(127, 143, 164, 0.5);
      //color: #7F8FA4;
    }
    .select__single-value {
      color: #1A1A1A;
    }
    .select__input {
      color: #1A1A1A;
      font-family: "GothaProReg",sans-serif;
      font-size: 10px;
      input {
        font-size: 12px;
      }
    }
    .select__indicator-separator {
      background: none;
    }
    .select_indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: #1A1A1A;
        font-size: 14px;
      }
    }
    .select__loading-indicator {
      span {
        background-color: #7F8FA4;;
      }
    }
    .select_close_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      cursor: pointer;
      svg {
        fill: #1A1A1A;
        font-size: 14px;
      }
    }
  }
  .select__menu {
    z-index: 9999;

    .select__menu-list {
      &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;

        background: #EDF2F8;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: #5CE6CD;
      }
      &::-webkit-scrollbar-thumb:window-inactive {
        background: #5CE6CD;
      }
      font-size: 10px;
      font-family: "GothaProReg", sans-serif;
      color: #9B9B9B;
      background: transparent;
      .select__option{
        background: transparent;
        color: #9B9B9B;
        cursor: pointer;
        &:hover{
          background-color: #EDEDED;
          color: #9B9B9B;
        }
      }
      .select__option--is-selected {
        color: #1A1A1A;
      }

      .status_select{
        padding: 0 12px;
        display: flex;
        align-items: center;
        background: transparent;
        &:hover{
          background: transparent;
        }
      }

    }
  }
}

.select_wrapper{
  height: 40px;
  width: 190px;
}
.status_select{
  display: flex;
  align-items: center;
  .up{
    margin-right: 10px;
  }
  .down{
    margin-right: 10px;
    transform: rotate(180deg);
  }
  span{
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 4px;
  }
  .consideration{
    background: #BD10E0;
  }
  .payment{
    background: #417505;
  }
  .during{
    background: #4A90E2;
  }
  .verification{
    background: #F5A623;
  }
  .completed{
    background: #5CE6CD;
  }
}



