.blogger_wrapper {
    .go_back {
        display: flex;
        padding-bottom: 30px;
        a {
            color: #9b9b9b;
            font-size: 12px;
            line-height: 12px;
            text-decoration: none;
            cursor: pointer;
            display: flex;
            align-items: center;
        }
        img {
            margin-bottom: 2px;
            margin-right: 6px;
        }
    }

    .blogger_info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .blogger_info_name {
            position: relative;
            padding-bottom: 50px;
            display: flex;
            .box_icon {
                img {
                    height: 60px;
                    width: 60px;
                }
            }
            .info {
                padding-left: 20px;
                display: flex;
                flex-flow: column;
                justify-content: center;
                > div {
                    font-family: "GothaProMed", sans-serif;
                    font-size: 16px;
                    line-height: 16px;
                    text-transform: uppercase;
                }
                a {
                    color: #4a90e2;
                    font-size: 12px;
                    line-height: 12px;
                    text-decoration: none;
                    padding-top: 8px;
                    img {
                        margin-right: 8px;
                    }
                }
            }
        }
        .menu_compaing {
            padding-bottom: 53px;
            button {
                margin-right: 32px;
                background: transparent;
                cursor: pointer;
            }
        }
    }

    .info_wrapper {
        padding-bottom: 40px;
        border-bottom: 2px solid rgba(151, 151, 151, 0.2);
        display: flex;
        margin-bottom: 40px;
        > div {
            display: flex;
            align-items: baseline;
            padding-right: 50px;
            span {
                font-family: "GothaProMed", sans-serif;
                font-size: 24px;
                padding-right: 10px;
            }
            p {
                text-transform: uppercase;
                color: #9b9b9b;
                font-size: 8px;
            }
        }
    }
}

.tab_blogger {
    margin-bottom: 50px;
    button {
        color: #9b9b9b;
        font-size: 14px;
        line-height: 13px;
        margin-right: 50px;
        font-family: "GothaProBold", sans-serif;
        background: transparent;
        cursor: pointer;
        text-transform: uppercase;
    }
    .active {
        color: #1a1a1a;
        position: relative;
        &:before {
            position: absolute;
            content: "";
            left: 0;
            bottom: -20px;
            width: 50px;
            height: 2px;
            background: #5ce6cd;
        }
    }
}

.block_wrapper_bg {
    box-shadow: 0 6px 24px -6px rgba(0, 34, 24, 0.15);
}

.blogger_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
}

.delete_blogger {
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    &:hover {
        background: #d0d0d0;
        transform: scale(0.9);
    }
}

.delete_blogger_dialog {
    display: flex;
    flex-flow: column;
    align-items: center;
    img {
        margin-bottom: 52px;
        margin-top: 40px;
    }
    span {
        color: #1a1a1a;
        font-family: "GothaProMed", sans-serif;
        font-size: 24px;
    }
    .btn_wrapper {
        padding-top: 40px;
        margin-top: 40px;
        border-top: 2px solid rgba(151, 151, 151, 0.2);
        display: flex;
        justify-content: center;
        width: 100%;
        padding-bottom: 10px;
        button {
            height: 50px;
            width: 190px;
            margin: 0 10px;
        }
    }
}
