.datepicker{
  padding: 0!important;
  div{
    padding-right:0!important;
  }


  >div{
    margin: 0;
    >div{
      position: relative;
      border: 1px solid #EDEDED;
      padding: 0 20px!important;
      height: 50px;
      width: 145px;

      &:before{
        display: none;
      }
      &:after{
        display: none;
      }
      >div{
        position: absolute;
        right: 15px;
        width: 16px;
        height: 16px;
        top: calc(50% - 8px);
        >button{
          padding: 0;
          &:before{
            position: absolute;
            content: "";
            left: 0;
            top: -8px;
            background: url("../../../assets/image/iconmonstr-calendar-small.svg");
            width: 16px;
            height: 16px;

          }
          span{
            right: auto;
            top: auto;
            position: unset;
            display: none;
          }
        }
      }
      input{
        width: 103px!important;
        font-family: "GothaProReg",sans-serif!important;
        font-size: 14px!important;
        padding: 0!important;
        border: 1px solid transparent!important;

      }
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
        border-left: none!important;
        border-right: none!important;
      }
    }
  }

}

.MuiFormHelperText-root.Mui-error{
  width: max-content;
  position: absolute;
  bottom: -20px;
  color: #D0021B!important;
  font-size: 10px;
  margin: 0;
  font-family: "GothaProMed",sans-serif!important;
}