.apply_dialog{
  .title{
    font-family: "GothaProBold",sans-serif;
    font-size: 32px;
    line-height: 30px;
    padding-bottom: 50px;
  }

}

.scroll_block{
  max-height: 400px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    margin-right: 5px;
    background: #EDF2F8;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #5CE6CD;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #5CE6CD;
  }
}

.block_info_blogger, .block_tasks_bloger {
  .buttons_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(151, 151, 151, 0.2);
    padding-top: 40px;
    margin-bottom: 10px;
    button, span {
      width: 190px;
    }
    span {
      margin-right: 20px;
    }
  }
  .box_tooltip{
    margin-left: 7px;
    cursor: pointer;
    position: relative;
    &:hover{
      .tooltip{
        display: flex;
      }
    }
    .tooltip{
      cursor: context-menu;
      font-family: "GothaProLig",sans-serif;
      flex-flow: column;
      position: absolute;
      display: none;
      width: 320px;
      border: 1px solid #EDEDED;
      color: #1A1A1A;
      font-size: 12px;
      line-height: 12px;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      background: #fff;
      text-align: center;
      box-sizing: border-box;
      left: -158px;
      top: 20px;
      &:before{
        position: absolute;
        content: "";
        left: calc(100% - 157px);
        background: #fff;
        transform: rotate(140deg);
        top: -5px;
        width: 6px;
        height: 6px;
        border-bottom: 1px solid #EDEDED;
        border-left: 1px solid #EDEDED;
      }
    }
  }
}

.block_info_blogger{
  .info_blogger{
    padding: 30px;
    box-shadow: 0 6px 24px -6px rgba(0,34,24,0.15);
    margin-bottom: 40px;
    .descriptions_projects{
      border: none;
      padding: 0 0 25px 0;
      margin: 0;
    }
    .comment_wrapper{
      textarea{
        box-sizing: border-box;
        height: 96px;
        width: 100%;
        border: 1px solid #EDEDED;
        resize: none;
        padding: 15px 20px ;
        font-size: 12px;
        color: #1A1A1A;
        font-family: "GothaProReg",sans-serif;
        &::placeholder{
          font-family: "GothaProLig",sans-serif;
          color: #9B9B9B;
        }
      }
    }
  }
  .title_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    .name_wrapper{
      div{
        display: flex;
        flex-flow: column;
        padding-bottom: 8px;
        font-family: "GothaProMed",sans-serif;
        font-size: 16px;
      }
      span{
        color: #9B9B9B;
        font-size: 12px;
      }
    }
    .box_icon{
      display: flex;
      align-items: center;
      .active{
        display: none;
      }
    }
  }
  .active_wrapper{
    .title_wrapper{
      .box_icon{
        .active{
          display: flex;
        }
        .no_active{
          display: none;
        }
      }
    }
  }
  .advertising{
    display: flex;
    flex-flow: column;
    padding-bottom: 24px;
    a{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #4A90E2;
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      line-height: 13px;
      text-decoration: none;
      //width: max-content;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .descriptions_text{
    padding-bottom: 12px;
    font-size: 12px;
    font-family: "GothaProMed",sans-serif;
  }
  .block_info_forms{
    padding-top: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid rgba(151, 151, 151, 0.2);

    .price{
      position: relative;
      display: flex;
      align-items: center;
      &.disabled {
        pointer-events: none;
        .box_tooltip {
          pointer-events: all;
        }
      }
      & > div{
        padding-right: 16px;
        font-size: 12px;
        display: flex;
      }
      .back_error {
        color: #D0021B;
        font-size: 10px;
        top: 60px;
        right: 0;
        font-family: "GothaProMed",sans-serif;

      }
      input{
        font-family: "GothaProMed",sans-serif;
        font-size: 14px;
        line-height: 13px;
        box-sizing: border-box;
        height: 50px;
        width: 145px;
        border: 1px solid #EDEDED;
        &:first-of-type{
          padding: 0 65px 0 20px;
        }
        &.guarantor {
          padding: 0 20px;
        }
      }


      span{
        position: absolute;
        right: 20px;
        top: calc(50% - 8px);
        color: #9B9B9B;
        font-size: 14px;
      }

    }
    .green_btn{
      height: 50px;
      width: 190px;
      font-size: 14px;
    }
  }
}

.block_tasks_bloger{
  .block_label{
    display: flex;
    flex-flow: column;
    padding-bottom: 30px;
    .link-textarea {
      color: #4A90E2;
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      padding: 22px 20px;
      border: 1px solid #EDEDED;
    }
    div{
      padding-bottom: 16px;
      font-family: "GothaProMed",sans-serif;
      font-size: 12px;
      line-height: 12px;
    }
    input{
      box-sizing: border-box;
      height: 62px;
      width: 100%;
      border: 1px solid #EDEDED;
      padding: 0 20px;
      font-size: 14px;
      line-height: 13px;
      font-family: "GothaProReg",sans-serif;
      color: #1A1A1A;
      &::placeholder{
        color: #9B9B9B;
        font-family: "GothaProLig",sans-serif;
      }
    }
    .textarea{
      font-size: 12px;
      height: 96px;
      border: 1px solid #EDEDED;
      resize: none;
      padding: 15px 20px;
      &::placeholder{
        color: #9B9B9B;
        font-family: "GothaProLig",sans-serif;
      }
    }
  }
  .check_box{
    display: flex;
    flex-flow: column;
    padding-bottom: 30px;
    .col-checkbox{
      user-select: none;
      display: flex;
      label{
        color: #1A1A1A;
        font-family: "GothaProReg",sans-serif;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        cursor: pointer;
        span {
          min-width: 12px;
        }
      }
    }
    .first_block{
      padding-bottom: 15px;
    }
  }
  .block_info_forms{
    padding-top: 40px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid rgba(151, 151, 151, 0.2);
    padding-bottom: 30px;
    .price{
      position: relative;
      display: flex;
      align-items: center;
      & > div{
        padding-right: 16px;
        font-size: 12px;
        display: flex;
      }
      .back_error {
        color: #D0021B;
        font-size: 10px;
        top: 60px;
        right: 0;
        font-family: "GothaProMed",sans-serif;
      }
      input{
        font-family: "GothaProMed",sans-serif;
        font-size: 14px;
        line-height: 13px;
        box-sizing: border-box;
        height: 50px;
        width: 145px;
        border: 1px solid #EDEDED;
        padding: 0 60px 0 20px;
        &.guarantor{
          padding: 0 20px;
        }
      }

      span{
        position: absolute;
        right: 20px;
        top: calc(50% - 8px);
        color: #9B9B9B;
        font-size: 14px;
      }


    }
    .green_btn{
      height: 50px;
      width: 190px;
      font-size: 14px;
    }
  }
  .scroll_block{
    padding: 15px;
  }
}

.campaigns{
  width: 100%;
  padding-bottom: 30px;
  .text_campaigns{
    padding-bottom: 16px;
    font-family: "GothaProMed",sans-serif;
    font-size: 12px;
    line-height: 12px;
    display: flex;

  }

  .select_wrapper{
    height: 50px;
    width: 50%;

    .status_select{
      >div{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px ;
      }
    }
    .select__placeholder {
      font-size: 14px ;
      font-weight: 400!important;
      font-family: GothaProReg;
      color: #9B9B9B;
    }
    .select_component .select__control{
      border: 1px solid #EDEDED;
      background: #ffffff;
      border-radius: 0;
      box-shadow: none;
      .select__value-container{
        padding: 2px 8px 2px 20px;

      }
    }
    .select__indicator {
      padding: 8px 19px;
    }
  }
}

.empty_tasks{
  font-family: "GothaProMed",sans-serif;
  font-size: 16px;
}

