.panel_wrapper{
  max-width: 260px;
  padding: 50px 0;
  width: 100%;
}

.panel{
  height: 100%;
  border-right: 1px solid rgba(151, 151, 151, 0.2);
  padding: 0 22px;
  a{
    text-decoration: none;
    cursor: pointer;
    color: #1A1A1A;
  }
  .search_panel{
    width: 100%;
    height: 40px;
    position: relative;
    background: #EDEDED;
    margin-bottom: 26px;
    a{
      font-size: 10px;
      align-items: center;
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      cursor: pointer;
      position: relative;
      font-family: "GothaProBold",sans-serif;
      text-transform: uppercase;
      img{
        position: absolute;
        left: 14px;
      }
    }

  }
  .add_company{
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    .add{
      width: 100%;
      opacity: 0.75;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >div{
        cursor: pointer;
      }
    }
    >p{
      opacity: 0.75;
      font-size: 14px;
      cursor: pointer;
    }

  }

  .info_company{
    display: flex;
    flex-flow: column;
    width: 100%;
    .company{
      margin-top: 25px;
      display: flex;
      align-items: center;
      span {
        text-align: center;
        border-radius: 50%;
        margin-right: 12px;
        padding: 4px;
      }
      div{
        opacity: 0.75;
        color: #1A1A1A;
        font-family: "GothaProLig",sans-serif;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 15px;
      }
      .green_status{
        background: #2CC197;
      }
      .yellow_status{
        background: #F0C57D;
      }

    }

  }

  .deals{
    opacity: 0.75;
    font-size: 14px;
    padding-bottom: 19px;
    width: 100%;
    display: flex;
  }
  .support{
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    padding: 19px 0;
    opacity: 0.75;
    font-size: 14px;
    display: flex;
  }
  .reference{
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    padding: 19px 0;
    opacity: 0.75;
    font-size: 14px;
    display: flex;
  }
  .info_wrapper{
    a{

      opacity: 0.5;
      font-size: 14px;
      display: flex;
      padding: 19px 0;
    }
  }
}

.status_bg{
  height: 8px;
  width: 8px;
  border-radius: 50% ;
}

.block_wrapper_status{
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  >a{
    padding: 19px 0;
    display: flex;
    align-items: center;
    span{
      padding-left: 12px;
      opacity: 0.75;
      font-family: "GothaProLig",sans-serif;
      font-size: 14px;
    }
  }
}

.add_company_dialog{
  display: flex;
  flex-flow: column;
  .title{
    color: #1A1A1A;
    font-family: "GothaProBold",sans-serif;
    font-size: 32px;
    padding-bottom: 30px;
    line-height: 30px;
  }
  .add_company_form{
    display: flex;
    flex-flow: column;
    .col{
      display: flex;
      margin-bottom: 30px;
      flex-flow: column;
      label{
        font-family: "GothaProMed",sans-serif;
        font-size: 12px;
        line-height: 12px;
        padding-bottom: 12px;
      }
      input{
        display: flex;
        box-sizing: border-box;
        width: 100%;
        border: 1px solid #EDEDED;
        color: #1A1A1A;
        height: 50px;
        padding: 0 20px;
        &::placeholder{
          color: #979797;
        }
      }
    }
    .audience{
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      padding-bottom: 20px;
    }
    .audience_block{
      display: flex;
      padding-bottom: 30px;
      .floor{
        margin-right: 38px;
        span{
          padding-bottom: 16px;
          font-family: "GothaProMed",sans-serif;
          font-size: 12px;
          line-height: 12px;
          display: flex;
        }
        >div{
          display: flex;
          button{
            width: 50px;
            height: 50px;
            border: 1.5px solid #EDEDED;
            cursor: pointer;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
          }
          .active{
            border: 1.5px solid #5CE6CD;
          }
        }
      }
      .age{
        width: 100%;
        .text_age{
          padding-bottom: 16px;
          font-family: "GothaProMed",sans-serif;
          font-size: 12px;
          line-height: 12px;
          display: flex;
        }
        .select_wrapper{
          height: 50px;
          .select_component .select__control{
            border: 1px solid #EDEDED;
            background: #ffffff;
            border-radius: 0;
            box-shadow: none;
            .select__value-container{
              padding: 2px 8px 2px 20px;
            }
          }
          .select__indicator {
            padding: 8px 19px;
          }
        }
      }
      .select__menu {
        border-radius: 0;
        box-shadow: none;
        border: 1px solid #EDEDED;
        .select__menu-list {
          font-size: 10px;
          font-family: "GothaProReg", sans-serif;
          color: #9B9B9B;
          background: transparent;
          .select__option{
            background: transparent;
            color: #9B9B9B;
            cursor: pointer;
            &:hover{
              color: #1A1A1A;
              background: transparent;
            }
          }

          .status_select{
            padding: 0 12px;
            display: flex;
            align-items: center;
            background: transparent;
            &:hover{
              background: transparent;
              color: #1A1A1A;
            }
          }

        }
      }
    }
    .text_info{
      position: relative;
      margin-bottom: 30px;
      p{
        font-family: "GothaProMed",sans-serif;
        font-size: 12px;
        line-height: 12px;
        padding-bottom: 15px;
      }
      textarea{
        width: 100%;
        height: 96px;
        box-sizing: border-box;
        border: 1px solid #EDEDED;
        resize: none;
        padding: 15px 20px;
      }
    }
    .value_info{
      position: relative;
      width: 145px;
      margin: 0 auto 0 0;
      .bydget_text{
        color: #1A1A1A;
        font-family: "GothaProMed",sans-serif;
        font-size: 12px;
        line-height: 12px;
        padding-bottom: 16px;
        display: flex;
      }
      input{
        box-sizing: border-box;
        height: 50px;
        width: 145px;
        border: 1px solid #EDEDED;
        font-size: 12px;
        font-family: "GothaProReg",sans-serif;
        padding: 0 50px 0 20px ;
        &::placeholder{
          color: #979797;
          font-family: "GothaProLig",sans-serif;
        }
      }
      p{
        position: absolute;
        right: 20px;
        color: #9B9B9B;
        font-family: "GothaProLig",sans-serif;
        font-size: 12px;
        line-height: 12px;
        top:calc(50% - -8px);
      }
    }
    .btn_wrapper{
      display: flex;
      justify-content: center;
      padding-top: 30px;
      margin-top: 30px;
      border-top: 2px solid rgba(151, 151, 151, 0.2);
      button{
        height: 50px;
        font-size: 14px;
        width: 190px;
        margin: 0 10px;
      }
    }
  }
  .dialog_scroll_block{
    max-height: 400px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;

      background: #EDF2F8;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: #5CE6CD;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: #5CE6CD;
    }
  }
}

