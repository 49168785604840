.promotional_block{
  display: flex;
  padding: 40px 0;
  border-bottom: 2px solid rgba(151, 151, 151, 0.2);
  flex-flow: column;
  .title{
    font-family: "GothaProMed",sans-serif;
    font-size: 14px;
    line-height: 13px;
    padding-bottom: 32px;
  }
  .promotional_block_wrapper{
    display: flex;
    a{
      color: #4A90E2;
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      line-height: 13px;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .block_video{
    margin-right: 40px;
    height: 180px;
    width: 320px;
    min-width: 320px;
  }
  .info_video{
    display: flex;
    flex-flow: column;
    width: 100%;
    justify-content: center;
    .info{
      .descriptions{
        padding-bottom: 15px;
        font-family: "GothaProMed",sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        line-height: 16px;
      }
      .content_info{
        display: flex;
        >div{
          margin-right: 32px;
          color: #979797;
          font-size: 12px;
          line-height: 12px;
          display: flex;
          align-items: center;
          img{
            margin-right: 8px;
          }
        }
        >div:last-of-type{
          display: flex;
          >div{
            display: flex;
            align-items: center;
          }
          .line_vertical{
            margin: 0 8px;
            display: flex;
            background: rgba(151, 151, 151, 0.2);
            width: 1px;
            height: 100%;
          }
        }
      }
    }
    .line{
      margin: 25px 0;
      height: 1px;
      width: 100%;
      background: rgba(151, 151, 151, 0.2) ;
      display: flex;
    }
  }
  .statistics_wrapper{
    display: flex;
    .statistics_block{
      align-items: center;
      display: flex;
      width: 33.3%;
      img{
        margin-right: 12px;
      }
      div{
        display: flex;
        flex-flow: column;
        span{
          font-family: "GothaProMed",sans-serif;
          font-size: 16px;
          line-height: 16px;
          padding-bottom: 4px;
        }
        p{
          color: #979797;
          font-family: "GothaProReg",sans-serif;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
}
