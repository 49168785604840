.tasks{
  .no_tasks{
    padding: 80px 0;
    display: flex;
    flex-flow: column;
    text-align: center;
    .descriptions{
      color: #9B9B9B;
      font-size: 24px;
      line-height: 24px;
      padding-top: 32px;
    }
  }
  .transactions_columns {
    width: 100%;
    .table_header {
      .row_item {
        display: flex;
        align-items: center;
      }
    }
    .table_body{
      .table_row{
        height: 80px;
        .row_item{
          color: #1A1A1A;
        }
      }
    }
    .table_row {
      & > :first-child {
        width: 10%;
        margin-left: 30px;
      }
      & > :nth-child(2) {
        width: 15%;
        font-family: "GothaProMed",sans-serif;
      }
      & > :nth-child(3) {
        width: 24%;
        min-width: 24%;
        a{
          display: flex;
          box-sizing: border-box;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #4A90E2;
          font-family: "GothaProMed",sans-serif;
          text-decoration: none;
          cursor: pointer;
          &:hover{
            text-decoration: underline;
          }
        }
      }
      & > :nth-child(4) {
        margin-left: 1%;
        width: 12%;
      }
      & > :nth-child(5) {
        width: 12%;
        .text_vivid_green{
          color: #2CC197;
        }
        .text_dark_green {
          color: #9B9B9B;
        }
      }
      & > :nth-child(6) {
        width: 11%;
      }
      & > :last-child {
        margin-right: 30px;
        display: flex;
        justify-content: flex-end;
        width: 15%;
        button{
          background: transparent;
          cursor: pointer;
          margin-left: 20px;
        }
      }
    }
  }
}
.info_text_dialog{
  font-size: 12px;
  div{
    padding-bottom: 15px;
    display: inline-block;
    width: 100%;
    p{
      display: inline-block;
    }
    span{
      display: inline-block;
    }
  }
  .title{
    padding-bottom: 30px;
    text-transform: uppercase;
    font-family: "GothaProMed",sans-serif;
  }
  .descriptions{
    font-family: "GothaProMed",sans-serif;
  }
  span{
    display: flex;
  }
  .pagination_block{
    .pagination-container{
      padding: 30px;
      border-top: 1px solid rgba(151, 151, 151, 0.2);
    }
  }
}