.lecture_hall_wrapper {
  .block_wrapper_bg {
    padding: 40px 50px 60px;
    .bar_chart_wrapper {
      height: 350px;
      width: 100%;
    }
    .doughnut_chart_wrapper {
      display: flex;
      margin-top: 80px;
      height: 450px;
      width: 100%;
      & > div {
        &:last-of-type {
          margin-left: 5%;
        }
        width: 50%;
      }
      .countries_title {
        color: #1A1A1A;
        font-size: 14px;
        font-family: "GothaProMed",sans-serif;
        margin-bottom: 20px;
      }
      .country_block {
        height: 60px;
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(9, 151, 151, 0.2);
        .icon_block {
          width: 40%;
          display: flex;
          .flag-icon {
            border-radius: 50%;
            border: 1px solid #EDEDED;
          }
          .others{
            font-family: "GothaProReg",sans-serif;
            color: #1A1A1A;
            font-size: 14px;
            line-height: 18px;
          }
        }
        .progress_bar {
          width: 50%;
          height: 2px;
          background-color: #EDEDED;
          .progress_track {
            background-color: #5CE6CD;
            height: 2px;
          }
        }
        .percentage {
          text-align: end;
          width: 10%;
          font-family: "GothaProMed",sans-serif;
          font-size: 16px;
        }
        .country_name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: "GothaProReg",sans-serif;
          color: #1A1A1A;
          font-size: 14px;
          margin-left: 12px;
          line-height: 18px;
        }
      }
    }
  }
}