.dashboard_wrapper{
  h1{
    padding-bottom: 50px;
  }
  .dashboard{
    display: flex;
    justify-content: space-between;

    .icon_user{
      padding-bottom: 30px;
      display: flex;
      justify-content: center;
    }
    .title{
      font-family: "GothaProMed",sans-serif;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      padding-bottom: 10px;
    }
    .descriptions{
      font-size: 14px;
      text-align: center;
      position: relative;
      margin-bottom: 60px;
      height: 36px;
      line-height: 13px;
      &:before{
        position: absolute;
        content: "";
        bottom: -17px;
        left: calc(50% - 25px);
        height: 1px;
        width: 50px;
        background: #5CE6CD;
      }
    }
    .step_block{
      .step_title{
        padding-bottom: 28px;
        font-family: "GothaProMed",sans-serif;
        font-size: 14px;
        line-height: 13px;
      }
      .info_step{
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 24px;
        position: relative;
        &:before{
          position: absolute;
          content: "";
          left: 9px;
          bottom: -22px;
          background: #5CE6CD;
          width: 2px;
          height: 20px;
        }
        span{
          height: 16px;
          width: 16px;
          border: 2px solid #5CE6CD;
          border-radius: 50%;
          margin-right: 16px;
        }
      }
      .info_step_ok{
        font-size: 14px;
        display: flex;
        position: relative;
        margin-bottom: 56px;
        align-items: center;
        &:before{
          position: absolute;
          content: "";
          bottom: -25px;
          left: calc(50% - 25px);
          height: 1px;
          width: 50px;
          background: #5CE6CD;
        }
        span{
          margin-right: 16px;
        }
      }
    }
    .lk_block_left{
      padding: 40px 50px;
      box-shadow: 0 8px 32px -8px rgba(0,34,24,0.15);
      margin-right: 40px;
      width: 50%;
    }

    .lk_block_right{
      padding: 40px 50px;
      box-shadow: 0 8px 32px -8px rgba(0,34,24,0.15);
      width: 50%;
      .info_step{
        margin-bottom: 34px;
        position: relative;
        &:before{
          height: 36px;
          position: absolute;
          content: "";
          left: 9px;
          bottom: -38px;
          background: #5CE6CD;
          width: 2px;
        }
      }
      .info_step_second{
        &:before{
          height: 35px;
          bottom: -31px;
        }
      }
      .info_step_three{
        &:before{
          height: 29px;
          bottom: -32px;
        }
      }
    }
  }
  .green_btn{
    text-transform: uppercase;
  }
}