.pagination-container{
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 0 0;
  background-color: transparent;
}

.pagination-list {
  display: flex;
  .disabled{
    a{
      //cursor: auto;
    }
  }
  li {
    border-radius: 2px;
    width: 28px;
    height: 28px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1px;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    &:hover{
      border: 1px solid #5CE6CD;
    }
    a{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}


.pagination-link {
  cursor: pointer;
  background: transparent;
  color: #1A1A1A;
  text-decoration: none;
  text-align: center;
  line-height: 28px;
  height: 28px;
  width: 28px;
  font-size: 12px;
  min-width: 28px;
  &.pagination-link-active {
    border-radius: 2px;
    background-color: #5CE6CD;
    box-shadow: 0 2px 42px 0 rgba(0,0,0,0.08);
    color: #ffffff;
    &:hover{
      opacity: 0.7;
    }
  }
}

.pagination-ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  a {
    cursor: pointer;
    background: transparent;
    height: 28px;
    width: 28px;
    color: #1A1A1A;
    text-decoration: none;
    text-align: center;
    line-height: 28px;
  }
}
.next, .previous {
  margin: 0;

  &.disabled {
    &:hover{
      border: 1px solid transparent;
    }
    a {
      opacity: 0.5;
      cursor: not-allowed;

    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 16px;
      fill: #1A1A1A;
    }
  }
}