.deals_inner_page{
  .btn_back{
    margin-bottom: 30px;
    a{
      align-items: center;
      display: flex;
      color: #9B9B9B;
      font-size: 12px;
      line-height: 12px;
      text-decoration: none;
      img{
        margin-right: 6px;
        margin-top: -2px;
      }
    }
  }
  .info_blogger{
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 2px solid rgba(151, 151, 151, 0.2);
    .channel{
      display: flex;
      flex-flow: column;
      span{
        font-family: "GothaProMed",sans-serif;
        padding-bottom: 8px;
        font-size: 16px;
        line-height: 16px;
      }
      a{
        color: #4A90E2;
        font-size: 12px;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  .box_arrow{
    display: flex;
    align-items: center;
    margin: 0 32px;
    &.reverse {
      transform: rotate(180deg);
    }
  }
  .blogger{
    display: flex;
    align-items: center;
    .photo{
      margin-right: 20px;
      display: flex;
      img{
        border-radius: 50%;
        width: 60px;
        height: 60px;
        box-sizing: border-box;
      }
    }
    .name{
      display: flex;
      flex-flow: column;
      div{
        padding-bottom: 8px;
        font-family: "GothaProMed",sans-serif;
        font-size: 16px;
        text-transform: uppercase;
      }
      a{
        color: #4A90E2;
        font-size: 12px;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
        img{
          margin-right: 8px;
        }
      }
    }
  }

  .chat_deals{
    display: flex;
    padding: 40px 0;
    border-bottom: 2px solid rgba(151, 151, 151, 0.2);
    flex-flow: column;
    & > span{
      color: #979797;
      font-family: "GothaProLig",sans-serif;
      font-size: 12px;
      margin: -12px 0 32px;
      display: flex;
    }
    & > p{
      padding-bottom: 32px;
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      line-height: 13px;
    }
    .descriptions_text{
      padding-bottom: 16px;
      font-family: "GothaProMed",sans-serif;
      font-size: 12px;
      line-height: 12px;
    }
    textarea{
      padding: 16px 20px;
      border: 1px solid #EDEDED;
      resize: none;
      width: 100%;
      box-sizing: border-box;
      height: 96px;
      margin-bottom: 16px;
    }
    button{
      height: 40px;
      width: 150px;
      font-size: 10px;
    }
  }
  .deal_history{
    padding-top: 40px;
    display: flex;
    flex-flow: column;
    .title{
      padding-bottom: 32px;
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      line-height: 13px;
    }
  }
}
.status_history_wrapper{
  >div{
    position: relative;
    &:before{
      position: absolute;
      content: "";
      border-left: 2px dotted #ededed;
      width: 0px;
      height: 43px;
      left: 7px;
      top: -37px;
    }
  }
  >div:first-of-type{
    &:before{
      display: none;
    }
  }
  .status_history{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .info_status{
      margin-right: 16px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    .text{
      display: flex;
      flex-flow: column;
      div{
        padding-bottom: 8px;
        color: #9B9B9B;
        font-size: 12px;
      }
      p{
        font-family: "GothaProMed",sans-serif;
        font-size: 14px;
        line-height: 13px;
        padding-right: 3px;
      }
      span{
        font-size: 14px;
        line-height: 13px;
        display: flex;
      }
    }
  }
}

.chat_block_info{
  display: flex;
  flex-flow: column;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);
  p{
    color: #9B9B9B;
    font-family: "GothaProLig",sans-serif;
    font-size: 10px;
    line-height: 10px;
    padding-left: 12px;
    margin-top: 5px;
  }
  .chat_wrapper{
    display: flex;
    align-items: center;
    span{
      font-size: 14px;
      font-family: "GothaProMed", sans-serif;
    }
  }
  .message{
    padding-top: 20px;
    font-family: "GothaProLig",sans-serif;
    font-size: 12px;
    line-height: 12px;
  }
}