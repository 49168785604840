.block-input-pass{
  display: block;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  &:before{
    content: "";
    right: 20px;
    top: 14px;
    background-image: url('../../../assets/image/password_icon.svg');
    width: 25px;
    height: 26px;
    display: block;
    position: absolute;
  }
  .error{
    display: none;
  }
  &.error_border,
  &.value.error_border {
    position: relative;
    &:before{
      content: "";
      right: 20px;
      top: 14px;
      background-image: url('../../../assets/image/password_error_icon.svg');
      width: 25px;
      height: 26px;
      display: block;
      position: absolute;
    }
    input {
      border: 1px solid #D0021B !important;
      color: #D0021B !important;
    }
    .error {
      bottom: -18px;
      display: block;
      color: #D0021B;
      position: absolute;
      right: 0;
      font-size: 10px;
      line-height: 13px;
    }
  }
  input{
    background: #fff;
    display: block;
    height: 50px;
    line-height: 50px;
    width: 100%;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 50px;
    color: #1A1A1A;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    &:focus{
      border: 1px solid #9B9B9B;
    }
    &::placeholder{
      color: #9B9B9B;
    }
  }
}




.block-input-mail{
  display: block;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  &:before{
    content: "";
    right: 20px;
    top: 14px;
    background-image: url('../../../assets/image/email_icon.svg');
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
  }
  &.error_border,
  &.value.error_border {
    position: relative;
    &:before{
      content: "";
      right: 20px;
      top: 14px;
      background-image: url('../../../assets/image/email_error_icon.svg');
      width: 24px;
      height: 24px;
      display: block;
      position: absolute;
    }
    input {
      border: 1px solid #D0021B !important;
      color: #D0021B !important;

    }
    span.error {
      color: #D0021B;
      position: absolute;
      bottom: -18px;
      right: 0;
      font-size: 10px;
      line-height: 13px;
      display: block;
    }
  }
  input{
    background: #fff;
    display: block;
    height: 50px;
    line-height: 50px;
    width: 100%;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 50px;
    color: #1A1A1A;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    &:focus{
      border: 1px solid #9B9B9B;
    }
    &::placeholder{
      color: #9B9B9B;
    }
  }
}

