.wrapper_multiselect{
  position: relative;
  .select_placeholder {
    position: absolute;
    color: #1A1A1A;
    font-family: "GothaProReg",sans-serif;
    font-size: 10px;
    top: 36px;
    left: 13px;
  }
  >div{
    height: 32px;
    width: 280px;
    margin-top: 0;
    border: 1px solid #EDEDED;
    >svg{
      display: none;
    }
    &:before{
      display: none;
    }
    &:after{
      display: none;
    }
    >div{
      padding: 0px 34px 0 12px;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      color: #1A1A1A;
      font-family: "GothaProReg",sans-serif;
      font-size: 10px;
      &:focus{
        background: transparent;
      }
      &:before{
        position: absolute;
        right: 14px;
        top: calc(50% - 2px);
        content: "";
        background: url("../../../assets/image/arrow_down.png");
        height: 4px;
        width: 7px;
        background-size: 100% 100%;
      }
    }
  }
}