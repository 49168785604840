.documents_wrapper {
  border-top: 2px solid rgba(151, 151, 151, 0.2);
  display: flex;
  flex-flow: column;
  margin-top: 32px;
  padding-top: 32px;
  img {
    margin-right: 8px;
  }
  a {
    cursor: pointer;
    color: #4A90E2;
    font-family: "GothaProMed", sans-serif;
    font-size: 12px;
    margin-bottom: 16px;
  }
  p {
    user-select: none;
    cursor: pointer;
    margin-top: 14px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}