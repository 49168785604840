.finance{
  .finance_info{
    display: flex;
    flex-flow: column;
    padding-bottom: 30px;
    >div{
      font-size: 14px;
      line-height: 16px;
      display: flex;
      padding-bottom: 5px;
      >p{
        padding-left: 3px;
        font-family: "GothaProBold",sans-serif;
      }
    }
    >p{
      color: #1A1A1A;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .transactions_columns {
    width: 100%;
    .table_header {
      .row_item {
        display: flex;
        align-items: center;
      }
    }
    .table_body{
      .table_row{
        height: 80px;
        .row_item{
          color: #1A1A1A;
        }
      }
    }
    .table_row {
      & > :first-child {
        width: 12%;
        padding-left: 30px;
        >div{
          display: flex;
          flex-flow: column;
        }
      }
      & > :nth-child(2) {
        width: 12%;
      }
      & > :nth-child(3) {
        width: 12%;
        font-family: "GothaProBold",sans-serif;

      }
      & > :nth-child(4) {
        width: 13%;
      }
      & > :nth-child(5) {
        width: 13%;
      }
      & > :nth-child(6) {
        width: 13%;
        span{
          max-width: 135px;
        }
      }
      & > :nth-child(7) {
        width: 13%;
        .text_red{
          color: #D0021B;

        }
        .text_green{
          color: #2CC197;
        }
      }
      & > :last-child {
        width: 13%;
      }
    }
  }
  .pagination_block{
    .pagination-container{
      padding: 30px;
      border-top: 1px solid rgba(151, 151, 151, 0.2);
    }
  }

}