@font-face {
  font-family: "GothaProBold";
  src: url('../assets/fonts/GothaProBol.otf') format("opentype");
}
@font-face {
  font-family: "GothaProMed";
  src: url('../assets/fonts/GothaProMed.otf') format("opentype");
}
@font-face {
  font-family: "GothaProReg";
  src: url('../assets/fonts/GothaProReg.otf') format("opentype");
}

@font-face {
  font-family: "GothaProLig";
  src: url('../assets/fonts/GothaProLig.otf') format("opentype");
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic-ext');


@import "./reset";
@import "./base";
@import "./animations";