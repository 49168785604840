.deals{
  .no_tasks{
    padding: 80px 0;
    display: flex;
    flex-flow: column;
    text-align: center;
    .descriptions{
      color: #9B9B9B;
      font-size: 24px;
      line-height: 24px;
      padding-top: 32px;
    }
  }
  .transactions_columns {
    width: 100%;
    .table_header {
      .row_item {
        display: flex;
        align-items: center;
      }
    }
    .table_body{
      .table_row{
        height: 80px;
        cursor: pointer;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
        &:hover {
          background-color: #ededed;
        }
        .row_item{
          color: #1A1A1A;
          img {
            border-radius: 50%;
          }
        }
      }
    }
    .table_row {
      & > :first-child {
        width: 13%;
        margin-left: 30px;
      }
      & > :nth-child(2) {
        width: 19%;
        font-family: "GothaProMed",sans-serif;
        img{
          margin-right: 10px;
          width: 40px;
          height: 40px;
          background-size: 100% 100%;
        }
      }
      & > :nth-child(3) {
        width: 20%;
      }
      & > :nth-child(4) {
        width: 16%;
      }
      & > :nth-child(5) {
        width: 16%;
        font-family: "GothaProMed",sans-serif;
      }

      & > :last-child {
        width: 16%;
      }
    }
  }
  .status{
    display: flex;
    align-items: center;

    span{
      height: 8px;
      width: 8px;
      margin-right: 8px;
      border-radius: 50%;
    }
    .canceled{
      background: #D0021B;
    }
    .during{
      background: #4A90E2;
    }
    .awaiting_conf{
      background: #F0C57D;
    }
    .awaiting_response{
      background: #D2D2D2;
    }
    .done{
      background: #2CC197;
    }
  }
  .pagination_block{
    .pagination-container{
      padding: 30px;
      border-top: 1px solid rgba(151, 151, 151, 0.2);
    }
  }
}