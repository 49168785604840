.blogger_reviews{
  padding: 30px;
  margin-bottom: 40px;
  .title{
    font-family: "GothaProMed",sans-serif;
    font-size: 14px;
    padding-bottom: 30px;
  }
  .assessment{
    display: flex;
    align-items: baseline;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    span{
      color: #2CC197;
      font-family: "GothaProMed",sans-serif;
      font-size: 24px;
      line-height: 24px;
      padding-right: 10px;
    }
    p{
      color: #9B9B9B;
      font-size: 8px;
      text-transform: uppercase;
    }
  }
  .reviews{
    padding: 30px 0;
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    display: flex;
    flex-flow: column;
    .descriptions{
      padding-bottom: 6px;
      display: flex;
      align-items: baseline;
      div{
        padding-right: 10px;
        font-family: "GothaProMed",sans-serif;
        font-size: 14px;
        line-height: 13px;
      }
      span{
        color: #9B9B9B;
        font-family: "GothaProLig",sans-serif;
        font-size: 10px;
      }
    }
  }
  .rating_block{
    display: flex;
    padding-bottom: 20px;
    p{
      color: #9B9B9B;
      font-size: 8px;
      padding-left: 8px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      margin-bottom: -3px;
    }
  }
  .text{
    font-family: "GothaProLig",sans-serif;
    font-size: 12px;
    word-break: break-word;
  }
}

.no_reviews{
  font-family: "GothaProMed",sans-serif;
  font-size: 14px;
}