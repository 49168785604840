.price_list_wrapper{
  margin-bottom: 30px;
  .price_block{
    padding: 16px 30px;
    margin-bottom: 20px;
    justify-content: space-between;
    display: flex;
    >div{
      width: 50%;
    }
    .title{
      color: #1A1A1A;
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      display: flex;
      align-items: center;
      .box_tooltip{
        margin-left: 7px;
        cursor: pointer;
        position: relative;
        &:hover{
          .tooltip{
            display: flex;
          }
        }
        .tooltip{
          cursor: context-menu;
          font-family: "GothaProLig",sans-serif;
          flex-flow: column;
          position: absolute;
          display: none;
          width: 325px;
          border: 1px solid #EDEDED;
          color: #1A1A1A;
          font-size: 12px;
          line-height: 12px;
          align-items: center;
          justify-content: center;
          padding: 10px 15px;
          background: #fff;
          text-align: left;
          box-sizing: border-box;
          left: 19px;
          top: -5px;
          &:before{
            position: absolute;
            content: "";
            left: -4px;
            background: #fff;
            transform: rotate(45deg);
            top: 10px;
            width: 6px;
            height: 6px;
            border-bottom: 1px solid #EDEDED;
            border-left: 1px solid #EDEDED;
          }
        }

      }
    }
    .price_amount{
      display: flex;
      justify-content: flex-end;
      div{
        margin-right: 50px;
        font-family: "GothaProMed",sans-serif;
        font-size: 16px;
        display: flex;
        align-items: center;
      }
      button{
        font-size: 10px;
        height: 40px;
        width: 150px;
      }
    }
  }
  .descriptions{
    color: #1A1A1A;
    font-family: "GothaProLig",sans-serif;
    font-size: 14px;
    align-items: center;
    display: flex;
  }

  .price_block_big{
    padding: 0 30px;
    flex-flow: column;
    justify-content: space-between;
    display: flex;
    box-sizing: border-box;
    .title{
      padding: 30px 0;
      border: none;
      justify-content: flex-start;
      .box_tooltip{
        width: auto;
        .tooltip{
          width: 475px;
        }
      }

    }
    >div{
      box-sizing: border-box;
      border-top: 1px solid rgba(151, 151, 151, 0.2);
      width: 100%;
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      >div{
        width: 50%;
      }
    }
  }
}

.blogger_price_list_info{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .price{
    font-size: 12px;
    padding-right: 25px;
  }
  .block_price{
    border: 1px solid #EDEDED;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    width: 145px;
    box-sizing: border-box;
    height: 50px;
    align-items: center;
    p{
      font-family: "GothaProMed",sans-serif;
      font-size: 14px;
      input {
        width: 50px;
        font-size: inherit;
        font-family: inherit;

      }
      input::placeholder{
        color: #1a1a1a;
        opacity: 1;
        font-size: inherit;
        font-family: inherit;
      }
      input::-moz-placeholder {
        color: #1a1a1a;
        opacity: 1;
        font-size: inherit;
        font-family: inherit;
      }
      input.disable-placeholder::placeholder {
        color: #9B9B9B;
      }
      input.disable-placeholder::-moz-placeholder {
        color: #9B9B9B;
      }
    }
    .no_price{
      opacity: 0.3;
    }
    span{
      color: #9B9B9B;
      font-size: 14px;
    }
  }
}